import { RouteConfig } from 'vue-router'
import Layout from '@/layout/index.vue'

const chartsRouter: RouteConfig = {
  path: '/search',
  component: Layout,
  redirect: '/search/list',
  meta: {
    title: 'search',
    icon: 'el-icon-searchs',
    privileges: ['VIEW_SEARCHES']
  },
  children: [
    {
      path: 'create',
      component: () => import(/* webpackChunkName: "search-create" */ '@/views/search/create.vue'),
      meta: {
        title: 'createSearch',
        icon: 'el-icon',
        privileges: ['CREATE_SEARCHES']
      }
    },
    {
      path: 'edit/:id',
      component: () => import(/* webpackChunkName: "search-edit" */ '@/views/search/edit.vue'),
      name: 'EditSearch',
      meta: {
        title: 'editSearch',
        icon: 'el-icon',
        noCache: true,
        activeMenu: '/search/list',
        hidden: true,
        privileges: ['EDIT_SEARCHES', 'READ_SEARCHES']
      }
    },
    {
      path: 'list',
      component: () => import(/* webpackChunkName: "search-list" */ '@/views/search/list.vue'),
      meta: {
        title: 'searchList',
        icon: 'el-icon',
        privileges: ['LIST_SEARCHES']
      }
    }
  ]
}

export default chartsRouter

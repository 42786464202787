import axios from 'axios'
import router from './../router/index'
import { UserModule } from '@/store/modules/user'

const service = axios.create({
  timeout: 300000,
  headers: { 'X-Requested-With': 'XMLHttpRequest' }
})

service.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 403) {
      UserModule.ResetToken()
      return router.push('/login')
    }
    return Promise.reject(error)
  }
)

export default service

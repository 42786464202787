



























































import { Component, Prop, Vue } from 'vue-property-decorator'
import { AppModule, DeviceType } from '@/store/modules/app'
import { getMyNotifications } from '@/api/notifications'
import { CollectionRequestMeta, createFilter, SortOrder } from '@/utils/filter'

@Component({
  name: 'NotificationDrawer'
})

export default class extends Vue {
  @Prop({ required: true }) private show!: boolean

  private listQuery: CollectionRequestMeta = createFilter()
  private notifications: any[] = []
  private loading = false
  private page = 0
  private totalPages = 0

  get title() {
    return this.$t('groupDetail.newGroup')
  }

  get noMore() {
    return this.page >= this.totalPages
  }

  get disabled() {
    return this.loading || this.noMore
  }

  get drawerSize() {
    if (AppModule.device === DeviceType.Desktop) {
      return 25
    }
    return 100
  }

  private load() {
    this.page = this.page + 1
    this.getUserNotifications()
  }

  private handleClose() {
    AppModule.CloseNotifications()
  }

  private getLink(notification: any) {
    const meta = notification.meta || {}
    switch (meta.category) {
      case 'property': {
        return `/property/edit/${meta.item_id}`
      }
      case 'collaboration': {
        switch (meta.status) {
          case 'newly_added':
          case 'canceled': {
            return `/collaboration/incoming-list?id=${meta.item_id}`
          }
          case 'approved':
          case 'rejected': {
            return `/collaboration/outgoing-list?id=${meta.item_id}`
          }
          default: {
            return null
          }
        }
      }
      case 'recommendation': {
        if (!meta.type) {
          return null
        }
        switch (meta.status) {
          case 'newly_added':
          case 'canceled': {
            return `/recommendation/incoming/${meta.type}-list?id=${meta.item_id}`
          }
          case 'approved':
          case 'rejected': {
            return `/recommendation/outgoing/${meta.type}-list?id=${meta.item_id}`
          }
          default: {
            return null
          }
        }
      }
      default: {
        return null
      }
    }
  }

  private onOpen() {
    this.notifications = []
    this.totalPages = 0
    this.page = 1
    this.getUserNotifications()
  }

  private preparePropertyFilters() {
    if (!this.listQuery.query?.sort?.length) {
      this.listQuery.query?.sort?.push({
        field: 'date_created',
        order: SortOrder.desc
      })
    }
    if (this.listQuery.query?.pagination) {
      this.listQuery.query.pagination.page = this.page
    }
  }

  private async getUserNotifications() {
    this.loading = true
    try {
      this.preparePropertyFilters()
      const { data } = await getMyNotifications(this.listQuery)
      this.notifications.push(...data.collection)
      this.page = data.pagination.page
      this.totalPages = data.pagination.total_pages
    } catch (e) {}
    this.loading = false
  }
}

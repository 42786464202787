import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators'
import { login, logout, getUserInfo } from '@/api/auth'
import { getToken, setToken, removeToken } from '@/utils/cookies'
import store from '@/store'
import {extractLatLongFromLocation} from "@/utils";

export interface IUserState {
  id: string
  token: string
  name: string
  avatar: string
  introduction: string
  roles: string[]
  translations: any[]
}

@Module({ dynamic: true, store, name: 'user' })
class User extends VuexModule implements IUserState {
  public token = getToken() || ''
  public id = ''
  public name = ''
  public email = ''
  public phone = ''
  public avatar = ''
  public introduction = ''
  public officeId = ''
  public baseUrl = ''
  public user = null
  public translations = []
  public office: any = {}
  public officeCoords: any = null
  public roles: string[] = []
  public meta: any = {}

  @Mutation
  private SET_TOKEN(token: string) {
    this.token = token
  }

  @Mutation
  private SET_NAME(name: string) {
    this.name = name
  }

  @Mutation
  private SET_AVATAR(avatar: string) {
    this.avatar = avatar
  }

  @Mutation
  private SET_INTRODUCTION(introduction: string) {
    this.introduction = introduction
  }

  @Mutation
  private SET_ROLES(roles: string[]) {
    this.roles = roles
  }

  @Mutation
  private SET_ID(id: string) {
    this.id = id
  }

  @Mutation
  private SET_EMAIL(email: string) {
    this.email = email
  }

  @Mutation
  private SET_PHONE(phone: string) {
    this.phone = phone
  }

  @Mutation
  private SET_META(meta: any = {}) {
    this.meta = meta
  }

  @Mutation
  private SET_OFFICE_ID(id: string) {
    this.officeId = id
  }

  @Mutation
  private SET_BASE_URL(baseUrl: string) {
    this.baseUrl = baseUrl
  }

  @Mutation
  private SET_OFFICE(office: any) {
    this.office = office
  }

  @Mutation
  private SET_USER(user: any) {
    this.user = user
  }

  @Mutation
  private SET_TRANSLATIONS(translations: []) {
    this.translations = translations
  }

  @Mutation
  private SET_OFFICE_COORDS(office: any = {}) {
    this.officeCoords = extractLatLongFromLocation(office.location)
  }

  @Action
  public async Login(userInfo: { username: string, password: string }) {
    try {
      let { username, password } = userInfo
      username = username.trim()
      const { data } = await login({ username, password })
      setToken(data.session)
      this.SET_TOKEN(data.session)
    } catch (e) {
      return Promise.reject(new Error('unable_to_login'))
    }
  }

  @Action
  public ResetToken() {
    removeToken()
    this.SET_TOKEN('')
    this.SET_ROLES([])
  }

  @Action
  public async GetUserInfo() {
    if (this.token === '') {
      throw Error('GetUserInfo: token is undefined!')
    }
    const { data } = await getUserInfo()
    if (!data) {
      throw Error('Verification failed, please Login again.')
    }
    this.SET_ROLES(data.roles)
    this.SET_OFFICE_ID(data.office_id)
    this.SET_BASE_URL(data.base_url)
    this.SET_OFFICE(data.office)
    this.SET_USER(data)
    this.SET_OFFICE_COORDS(data.office)
    this.SET_NAME('name')
    this.SET_AVATAR(data.photo)
    this.SET_INTRODUCTION('introduction')
    this.SET_ID(data.id)
    this.SET_ID(data.id)
    this.SET_TRANSLATIONS(data.translations)
    this.SET_EMAIL(data.main_email)
    this.SET_PHONE(data.main_phone || data.secondary_phone)
    this.SET_META(data.meta)
  }

  @Action
  public async LogOut() {
    if (this.token === '') {
      throw Error('LogOut: token is undefined!')
    }
    await logout()
    removeToken()
    this.SET_TOKEN('')
    this.SET_ROLES([])
  }
}

export const UserModule = getModule(User)


























































import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/modules/app'
import { UserModule } from '@/store/modules/user'
import Breadcrumb from '@/components/Breadcrumb/index.vue'
import Hamburger from '@/components/Hamburger/index.vue'
import LangSelect from '@/components/LangSelect/index.vue'
import { getTranslations } from '@/utils'

@Component({
  name: 'Navbar',
  components: {
    Breadcrumb,
    LangSelect,
    Hamburger
  }
})
export default class extends Vue {
  get sidebar() {
    return AppModule.sidebar
  }

  get device() {
    return AppModule.device
  }

  getFullName() {
    try {
      return getTranslations(UserModule).split(' ').map((n) => {
        return (n && n[0]) ? n[0].toUpperCase() : ''
      }).join('')
    } catch (e) {
      console.log(e)
    }
  }

  get avatar() {
    return UserModule.avatar
  }

  private openNotification() {
    AppModule.OpenNotifications()
  }

  private toggleSideBar() {
    AppModule.ToggleSideBar(false)
  }

  private async logout() {
    await UserModule.LogOut()
    this.$router.push(`/login?redirect=${this.$route.fullPath}`)
  }
}

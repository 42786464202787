import { RouteConfig } from 'vue-router'
import Layout from '@/layout/index.vue'

const chartsRouter: RouteConfig = {
  path: '/call',
  component: Layout,
  redirect: '/call/list',
  meta: {
    title: 'call',
    icon: 'el-icon-calls',
    privileges: ['VIEW_CALLS']
  },
  children: [
    {
      path: 'create',
      component: () => import(/* webpackChunkName: "call-create" */ '@/views/call/create.vue'),
      meta: {
        title: 'createCall',
        icon: 'el-icon',
        privileges: ['CREATE_CALLS']
      }
    },
    {
      path: 'edit/:id',
      component: () => import(/* webpackChunkName: "call-edit" */ '@/views/call/edit.vue'),
      name: 'EditCall',
      meta: {
        title: 'editCall',
        icon: 'el-icon',
        noCache: true,
        activeMenu: '/call/list',
        hidden: true,
        privileges: ['EDIT_CALLS', 'READ_CALLS']
      }
    },
    {
      path: 'list',
      component: () => import(/* webpackChunkName: "call-list" */ '@/views/call/list.vue'),
      meta: {
        title: 'callList',
        icon: 'el-icon',
        privileges: ['LIST_CALLS']
      }
    }
  ]
}

export default chartsRouter

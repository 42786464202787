



















import { Component } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { DeviceType, AppModule } from '@/store/modules/app'
import { AppMain, Navbar, Sidebar } from './components'
import NotificationDrawer from '@/components/NotificationDrawer/index.vue'
import ResizeMixin from './mixin/resize'
import { initSocket } from '@/utils'

@Component({
  name: 'Layout',
  components: {
    AppMain,
    Navbar,
    NotificationDrawer,
    Sidebar
  }
})
export default class extends mixins(ResizeMixin) {
  get classObj() {
    return {
      hideSidebar: !this.sidebar.opened,
      openSidebar: this.sidebar.opened,
      withoutAnimation: this.sidebar.withoutAnimation,
      mobile: this.device === DeviceType.Mobile
    }
  }

  get showNotifications() {
    return AppModule.notifications
  }

  async mounted() {
    await initSocket((wrapper: any) => {
      if (wrapper.message.data.event !== 'notification_update' || this.$route.path === '/notifications') {
        return
      }
      this.$notify({
        title: wrapper.message.data.subject,
        message: wrapper.message.data.message,
        type: wrapper.message.data.type || null
      })
    })
  }

  private handleClickOutside() {
    AppModule.CloseSideBar(false)
  }
}

import request from '@/utils/request'
import { exportJson2Excel, prepareExcelHeaders } from '@/utils/excel'
import { formatJson } from '@/utils'

export const getOffices = (data: any) =>
  request({
    method: 'post',
    url: '/api/offices/getOffices',
    data
  })

export const getOfficesCount = (data: any) =>
  request({
    method: 'post',
    url: '/api/offices/getOfficesCount',
    data
  })

export const getOffice = (params: any) =>
  request({
    method: 'get',
    url: '/api/offices/getOffice',
    params
  })

export const saveOffice = (data: any) =>
  request({
    method: 'post',
    url: '/api/offices/saveOffice',
    data
  })

export const saveOfficeWithMedia = (formData: any) =>
  request.post('/api/offices/saveOfficeWithMedia', formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })

export const saveOfficeBulk = (data: any) =>
  request({
    method: 'post',
    url: '/api/offices/saveOfficeBulk',
    data
  })

export const deleteOffice = (params: any) =>
  request({
    method: 'get',
    url: '/api/offices/deleteOffice',
    params
  })

export const deleteOfficeBulk = (data: any) =>
  request({
    method: 'post',
    url: '/api/offices/deleteOfficeBulk',
    data
  })

export const exportOffices = (offices: any[]) => {
  const fields = [
    { key: 'code' },
    { key: 'name' },
    { key: 'main_email' },
    { key: 'main_phone' },
    { key: 'date_created' }
  ]
  return exportJson2Excel(
    prepareExcelHeaders(fields),
    formatJson(fields, offices),
    'office-list'
  )
}

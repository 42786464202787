import request from '@/utils/request'

export const getRoles = (data: any) =>
  request({
    method: 'post',
    url: '/api/roles/getRoles',
    data
  })

export const getRolesLight = (data: any) =>
  request({
    method: 'post',
    url: '/api/roles/getRolesLight',
    data
  })

export const getRolesCount = (data: any) =>
  request({
    method: 'post',
    url: '/api/roles/getRolesCount',
    data
  })

export const getRole = (params: any) =>
  request({
    method: 'get',
    url: '/api/roles/getRole',
    params
  })

export const saveRole = (data: any) =>
  request({
    method: 'post',
    url: '/api/roles/saveRole',
    data
  })

export const saveRoleBulk = (data: any) =>
  request({
    method: 'post',
    url: '/api/roles/saveRoleBulk',
    data
  })

export const deleteRole = (params: any) =>
  request({
    method: 'get',
    url: '/api/roles/deleteRole',
    params
  })

export const deleteRoleBulk = (data: any) =>
  request({
    method: 'post',
    url: '/api/roles/deleteRoleBulk',
    data
  })

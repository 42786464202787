import { DirectiveOptions } from 'vue'
import { UserModule } from '@/store/modules/user'

export const permission: DirectiveOptions = {
  inserted(el, binding) {
    const { value } = binding
    const roles = UserModule.roles
    if (value && value instanceof Array && value.length > 0) {
      const permissionRoles = value
      const hasPermission = roles.some((role: any) => {
        return role.privileges.some((privilege: any) => {
          return permissionRoles.includes(privilege.name)
        })
      })
      if (!hasPermission) {
        el.style.display = 'none'
      }
    } else {
      throw new Error('need permission!')
    }
  }
}

import { RouteConfig } from 'vue-router'
import Layout from '@/layout/index.vue'

const chartsRouter: RouteConfig = {
  path: '/support',
  component: Layout,
  redirect: '/support/list',
  meta: {
    title: 'support',
    icon: 'el-icon-support'
  },
  children: [
    {
      path: 'educational-material',
      component: () => import(/* webpackChunkName: "support-material" */ '@/views/support/educational-material.vue'),
      meta: {
        title: 'educationalMaterial',
        icon: 'el-icon',
      }
    },
    {
      path: 'users',
      component: () => import(/* webpackChunkName: "support-material" */ '@/views/support/users.vue'),
      meta: {
        title: 'supportUsers',
        icon: 'el-icon'
      }
    },
    {
      path: 'talks',
      component: () => import(/* webpackChunkName: "support-material" */ '@/views/support/talks.vue'),
      meta: {
        title: 'supportTalks',
        icon: 'el-icon'
      }
    },
    {
      path: 'ticketing',
      name: 'Menu1',
      component: () => import(/* webpackChunkName: "recommendation-create-client" */ '@/views/support/ticketing/index.vue'),
      meta: {
        title: 'ticketing',
        icon: 'el-icon'
      },
      children: [
        {
          path: 'new',
          component: () => import(/* webpackChunkName: "recommendation-create-client" */ '@/views/support/ticketing/create.vue'),
          meta: {
            title: 'newTicket',
            icon: 'el-icon'
          }
        },
        {
          path: 'edit/:id',
          component: () => import(/* webpackChunkName: "call-edit" */ '@/views/support/ticketing/edit.vue'),
          name: 'EditCall',
          meta: {
            title: 'editTicket',
            icon: 'el-icon',
            noCache: true,
            activeMenu: '/ticketing/list',
            hidden: true
          }
        },
        {
          path: 'list',
          component: () => import(/* webpackChunkName: "recommendation-create-property" */ '@/views/support/ticketing/list.vue'),
          meta: {
            title: 'listTicket',
            icon: 'el-icon'
          }
        }
      ]
    }
  ]
}

export default chartsRouter

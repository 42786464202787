import request from '@/utils/request'
import { exportJson2Excel, prepareExcelHeaders } from '@/utils/excel'
import { formatJson, getAreaFullTitle, getTranslations } from '@/utils'

export const getProperty = (params: any) =>
  request({
    method: 'get',
    url: '/api/properties/getProperty',
    params
  })

export const getPropertyTraffic = (params: any) =>
  request({
    method: 'get',
    url: '/api/properties/getPropertyTraffic',
    params
  })

export const setWatermark = (params: any) =>
  request({
    method: 'get',
    url: '/api/properties/setWatermark',
    params
  })

export const setImageRotation = (params: any) =>
  request({
    method: 'get',
    url: '/api/properties/setImageRotation',
    params
  })

export const getPropertyBridgeState = (params: any) =>
  request({
    method: 'get',
    url: '/api/properties/getPropertyBridgeState',
    params
  })

export const nextCode = (params: any) =>
  request({
    method: 'get',
    url: '/api/properties/nextCode',
    params
  })

export const getBridgeEvents = (data: any) =>
  request({
    method: 'post',
    url: '/api/properties/getBridgeEvents',
    data
  })

export const getPropertyHistory = (data: any) =>
  request({
    method: 'post',
    url: '/api/properties/getPropertyHistory',
    data
  })

export const getPropertiesCount = (data: any) =>
  request({
    method: 'post',
    url: '/api/properties/getPropertiesCount',
    data
  })

export const getProperties = (data: any) =>
  request({
    method: 'post',
    url: '/api/properties/getProperties',
    data
  })

export const getPublicProperties = (data: any) =>
  request({
    method: 'post',
    url: '/api/properties/getPublicProperties',
    data
  })

export const getPublicPropertiesCount = (data: any) =>
  request({
    method: 'post',
    url: '/api/properties/getPublicPropertiesCount',
    data
  })

export const getPublicAndOfflineProperties = (data: any) =>
  request({
    method: 'post',
    url: '/api/properties/getPublicAndOfflineProperties',
    data
  })

export const getPublicAndOfflinePropertiesCount = (data: any) =>
  request({
    method: 'post',
    url: '/api/properties/getPublicAndOfflinePropertiesCount',
    data
  })

export const getOfflineProperties = (data: any) =>
  request({
    method: 'post',
    url: '/api/properties/getOfflineProperties',
    data
  })

export const getOfflinePropertiesCount = (data: any) =>
  request({
    method: 'post',
    url: '/api/properties/getOfflinePropertiesCount',
    data
  })

export const saveProperty = (data: any) =>
  request({
    method: 'post',
    url: '/api/properties/saveProperty',
    data
  })

export const approvePropertyChanges = (data: any) =>
  request({
    method: 'post',
    url: '/api/properties/approvePropertyChanges',
    data
  })

export const changeOwner = (data: any) =>
  request({
    method: 'post',
    url: '/api/properties/changeOwner',
    data
  })

export const copyProperty = (data: any) =>
  request({
    method: 'post',
    url: '/api/properties/copyProperty',
    data
  })

export const rejectPropertyChanges = (data: any) =>
  request({
    method: 'post',
    url: '/api/properties/rejectPropertyChanges',
    data
  })

export const sentPropertyForApproval = (data: any) =>
  request({
    method: 'post',
    url: '/api/properties/sentPropertyForApproval',
    data
  })

export const sentToService = (data: any) =>
  request({
    method: 'post',
    url: '/api/properties/sentToService',
    data
  })

export const savePropertyBulk = (data: any) =>
  request({
    method: 'post',
    url: '/api/properties/savePropertyBulk',
    data
  })

export const savePropertyWithMedia = (formData: any) =>
  request.post('/api/properties/savePropertyWithMedia', formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })

export const deleteProperty = (params: any) =>
  request({
    method: 'get',
    url: '/api/properties/deleteProperty',
    params
  })

export const deletePropertyBulk = (data: any) =>
  request({
    method: 'post',
    url: '/api/properties/deletePropertyBulk',
    data
  })

export const exportOfflineProperties = (properties: any[]) => {
  const fields = [
    { key: 'full_code' },
    {
      key: 'client',
      type: 'custom',
      parse: (item: any) => {
        return getTranslations(item.client)
      }
    },
    {
      key: 'area',
      type: 'custom',
      parse: (item: any) => {
        return getAreaFullTitle({
          property: item
        })
      }
    },
    { key: 'price' },
    { key: 'acreage' },
    { key: 'floor' },
    { key: 'state' },
    { key: 'views' },
    {
      key: 'date_created',
      type: 'timestamp'
    }
  ]
  return exportJson2Excel(
    prepareExcelHeaders(fields),
    formatJson(fields, properties),
    'offline-properties-list'
  )
}

export const exportProperties = (properties: any[]) => {
  const fields = [
    { key: 'full_code' },
    {
      key: 'client',
      type: 'custom',
      parse: (item: any) => {
        return getTranslations(item.client)
      }
    },
    {
      key: 'area',
      type: 'custom',
      parse: (item: any) => {
        return getAreaFullTitle({
          property: item,
          withAddress: true
        })
      }
    },
    { key: 'price' },
    { key: 'acreage' },
    { key: 'floor' },
    { key: 'state' },
    { key: 'views' },
    {
      key: 'user',
      type: 'custom',
      parse: (item: any) => {
        return getTranslations(item.user)
      }
    },
    {
      key: 'date_created',
      type: 'timestamp'
    }
  ]
  return exportJson2Excel(
    prepareExcelHeaders(fields),
    formatJson(fields, properties),
    'properties-list'
  )
}

import request from '@/utils/request'

export const getUserInfo = () =>
  request({
    url: '/api/auth/getUserInfo',
    method: 'get'
  })

export const login = (auth: any) =>
  request({
    url: '/api/auth/login',
    method: 'get',
    auth
  })

export const logout = () =>
  request({
    url: '/api/auth/logout',
    method: 'get'
  })

/* eslint-disable camelcase */
export class TimeRange {
  from: number | null;
  to: number | null;

  constructor(data: any = {}) {
    this.from = data.from || null
    this.to = data.to || null
  }
}

import { RouteConfig } from 'vue-router'
import Layout from '@/layout/index.vue'

const chartsRouter: RouteConfig = {
  path: '/property',
  component: Layout,
  redirect: '/property/list',
  meta: {
    title: 'property',
    icon: 'el-icon-house-new',
    privileges: ['VIEW_PROPERTIES']
  },
  children: [
    {
      path: 'create',
      component: () => import(/* webpackChunkName: "property-create" */ '@/views/property/create.vue'),
      meta: {
        title: 'createProperty',
        icon: 'el-icon',
        privileges: ['CREATE_PROPERTIES']
      }
    },
    {
      path: 'edit/:id',
      component: () => import(/* webpackChunkName: "property-edit" */ '@/views/property/edit.vue'),
      name: 'EditProperty',
      meta: {
        title: 'editProperty',
        icon: 'el-icon',
        noCache: true,
        activeMenu: '/property/list',
        hidden: true,
        privileges: ['EDIT_PROPERTIES', 'READ_PROPERTIES', 'READ_OTHERS_PROPERTIES', 'EDIT_OTHERS_PROPERTIES']
      }
    },
    {
      path: 'list',
      component: () => import(/* webpackChunkName: "property-list" */ '@/views/property/list.vue'),
      meta: {
        title: 'propertyList',
        icon: 'el-icon',
        privileges: ['LIST_PROPERTIES']
      }
    },
    {
      path: 'offline-list',
      component: () => import(/* webpackChunkName: "property-offline-list" */ '@/views/property/offline-list.vue'),
      meta: {
        title: 'propertyOfflineList',
        icon: 'el-icon',
        privileges: ['LIST_PROPERTIES']
      }
    }
  ]
}

export default chartsRouter

import { RouteConfig } from 'vue-router'
import Layout from '@/layout/index.vue'

const blogPostRouter: RouteConfig = {
  path: '/blog',
  component: Layout,
  redirect: '/blog/list',
  meta: {
    title: 'blog',
    icon: 'el-icon-blog',
    privileges: ['VIEW_BLOG_POSTS']
  },
  children: [
    {
      path: 'create',
      component: () => import(/* webpackChunkName: "blog-post-create" */ '@/views/blog/create.vue'),
      meta: {
        title: 'createBlogPost',
        icon: 'el-icon',
        privileges: ['CREATE_BLOG_POSTS']
      }
    },
    {
      path: 'edit/:id',
      component: () => import(/* webpackChunkName: "blog-post-edit" */ '@/views/blog/edit.vue'),
      name: 'EditBlogPost',
      meta: {
        title: 'editBlogPost',
        icon: 'el-icon',
        noCache: true,
        activeMenu: '/blog/list',
        hidden: true,
        privileges: ['EDIT_BLOG_POSTS']
      }
    },
    {
      path: 'list',
      component: () => import(/* webpackChunkName: "blog-post-list" */ '@/views/blog/list.vue'),
      meta: {
        title: 'blogPostList',
        icon: 'el-icon',
        privileges: ['LIST_BLOG_POSTS']
      }
    }
  ]
}

export default blogPostRouter

export default {
  route: {
    dashboard: 'Management MENU',
    news: 'Updates',
    createNew: 'Create Update',
    newsList: 'Updates',
    client: 'Contacts',
    createClient: 'New contact',
    editClient: 'Edit contact',
    clientList: 'Contact list',
    call: 'Incoming',
    createCall: 'New call',
    editCall: 'Editing',
    callList: 'Call list',
    groupList: 'Group list',
    office: 'Offices',
    blog: 'Blog',
    createBlogPost: 'New Post',
    editBlogPost: 'Τροποποίηση',
    blogPostList: 'Λίστα Αναρτήσεων',
    createOffice: 'New office',
    editOffice: 'Edit office',
    editOfficePage: 'Edit Office Page',
    officeList: 'Office list',
    user: 'Collaborators',
    createUser: 'New collaborator',
    editUser: 'Edit collaborator',
    userProfile: 'Profile',
    userList: 'Collaborator list',
    property: 'Property',
    auction: 'Auction',
    visit: 'Hints',
    createVisit: 'New hint',
    visitList: 'Hint list',
    createProperty: 'New property',
    editProperty: 'Edit property',
    propertyList: 'Property list',
    propertyOfflineList: 'Offline properties',
    createAuction: 'New Auction',
    editAuction: 'Edit Auction',
    auctionList: 'Auction list',
    auctionOfflineList: 'Offline auctions',
    role: 'Role management',
    roleList: 'Roles',
    incoming: 'Incoming',
    outgoing: 'Outgoing',
    search: 'Searches',
    searchList: 'Search list',
    createSearch: 'New search',
    editSearch: 'Edit search',
    editNew: 'Edit new',
    privilege: 'Privileges',
    privilegeList: 'Privilege list',
    recommendation: 'Recommendations',
    collaboratorsStatistics: 'Statistics',
    createRecommendation: 'New',
    editRecommendation: 'Edit recommendation',
    recommendationList: 'Recommendation list',
    collaboration: 'Collaborations',
    createCollaboration: 'New collaboration',
    editCollaboration: 'Edit collaboration',
    collaborationList: 'Collaboration list',
    internalMail: 'Emails',
    webmail: 'Webmail - Company email',
    internalMailList: 'Emails',
    internalMailCreate: 'New',
    internalSmsCreate: 'New',
    ticketing: 'technical requirements',
    newTicket: 'New',
    listTicket: 'Requests',
    editTicket: 'Edit Requests',
    internalSmsList: 'SMS',
    supportList: 'Support list',
    support: 'support',
    create: 'Creation',
    edit: 'Editing',
    supportMaterial: 'Support material',
    educationalMaterial: 'Educational Material',
    supportUsers: 'Bee Agents',
    supportTalks: 'Talks',
    personalWebsiteManagement: 'Personal website management',
    incomingPropertyRecommendationList: 'Property',
    outgoingPropertyRecommendationList: 'Property',
    incomingClientRecommendationList: 'Contact',
    outgoingClientRecommendationList: 'Contact',
    createPropertyRecommendation: 'Property',
    createClientRecommendation: 'Contact',
    incomingCollaborationList: 'Incoming',
    outgoingCollaborationList: 'Outgoing'
  },
  dashboard: {
    title: 'Dashboard',
    collaborators: 'Active Collaborators',
    userStatistics: 'ΣΤΑΤΙΣΤΙΚΑ – ΣΥΝΕΡΓΑΤΗΣ',
    officeNewPropertiesTable: 'New office properties',
    spitogatosStatistics: 'Spitogatos Statistics',
    officeNewApprovalsTable: 'New office approvals',
    officeSentForApprovalTable: 'Properties to be approved',
    properties: 'Active Properties',
    news: 'News',
    searches: 'Active Searches',
    clients: 'Contacts',
    home: 'Home page',
    logOut: 'Logout',
    profile: 'Edit profile',
    propertiesForApproval: 'Ακίνητα προς Έγκριση',
    incomingCalls: 'Incoming calls',
    collaborations: 'Collaborations',
    assignments: 'Assignments',
    foundFrom: 'Source of contacts',
    visits: 'Hints',
    searches_with_new_properties_count: 'Ζητήσεις με νέα ακίνητα',
    timeline: 'Χρονολόγιο',
    recommendations: 'Recommendations',
    same_office: 'Office',
    other_office: 'Out of office',
    sale_exclusive: 'Exclusive sale',
    sale_simple: 'Simple sale',
    lease_exclusive: 'Exclusive lease',
    lease_simple: 'Simple lease',
    date_from: 'Show results from',
    date_to: 'Show results to',
    viewsPerProperty: 'Property traffic',
    today: 'Today',
    yesterday: 'Yesterday',
    last_seven_days: 'Last 7 days',
    current_week: 'Current week',
    last_thirty_days: 'Last 30 days',
    current_month: 'Current month',
    last_six_months: 'Last 6 months',
    last_year: 'Last year',
    current_year: 'Current year',
    choose_dates: 'Dates',
    custom: 'Select dates'
  },
  formValidationErrors: {
    invalidLatitude: 'Invalid Latitude',
    invalidLongitude: 'Invalid Longitude',
    yearValidation: 'Between 1901 and {currentYear}'
  },
  notificationList: {
    title: 'Ειδοποιήσεις'
  },
  clientList: {
    title: 'Contacts',
    delete: 'The selected contact is about to be permanently deleted. Do you want to take this action?',
    deleteBulk: 'The selected contacts are about to be permanently deleted. Do you want to take this action?',
    deleteSuccess: 'The contact was successfully deleted!',
    deleteBulkSuccess: 'The selected contacts were successfully deleted!',
    selectClients: 'Select the contacts you want to delete',
    warning: 'Warning',
    download: 'List export/ download',
    create: 'Creation of contact',
    deleteBulkClients: 'Bulk deletion of contacts',
    filters: 'Filters'
  },
  supportMaterial: {
    title: 'Support Material',
    commands: 'Commands',
    promotionalMaterial: 'Promotional Material',
    logos: 'Logos',
    logosForEmpty: 'Icons for Properties without Photos',
    registrationForms: {
      title: 'Registration Forms',
      residence: 'Residence Registration Form',
      professionalSpace: 'Professional Space Registration Form',
      land: 'Land Registration Form'
    },
    evaluationForms: {
      title: 'Evaluation Forms',
      buyer: 'Buyer Interest Evaluation Form',
      seller: 'Seller Evaluation Form',
      collaborator: 'Collaborator Evaluation Form'
    },
    searchForms: {
      title: 'Search Forms',
      property: 'Property Search Form'
    },
    propertyFolder: {
      title: 'Property Folder',
      propertyCard: 'Property Card',
      folder1: 'Property Folder-1',
      folder3: 'Property Folder-3'
    },
    cooperationWithOtherRealEstateAgencies: {
      title: 'Cooperation with Other Real Estate Agencies Forms',
      cooperation: 'Cooperation Form',
      recommendation: 'Recommendation Form'
    },
    offerForms: {
      title: 'Offer Forms',
      sale: 'Sale Offer Form',
      rent: 'Rent Offer Form'
    },
    leaseStandards: {
      title: 'Lease Standards',
      mainResidence: 'Main Residence Lease Agreement',
      professionalHousing: 'Professional Housing Lease Agreement'
    },
    marketingPlan: {
      title: 'Marketing Plan',
      presentation: 'Presentation',
      fonts: 'Required Fonts'
    },
    generalForms: {
      title: 'General Forms',
      prelistPack: 'PRELIST PACK - BEE REAL ESTATE Promotion Plan',
      thingsLoveHateAboutHome: '10 Things I Love/Would Change About My Home',
      openHouse: 'Open House Organization Chart',
      sellerDocuments: 'Required Documents for Property Sellers',
      englishVocabulary: 'English Vocabulary',
      advancePaymentReceipt: 'Advance Payment Receipt',
      callCard: 'Call Cards'
    },
    propertySale: {
      title: 'Property Sale',
      simpleAssignment1000: 'Simple Property Sale Assignment (Minimum Fee €1,000+VAT)',
      simpleAssignment1500: 'Simple Property Sale Assignment (Minimum Fee €1,500+VAT)',
      exclusiveAssignment1000: 'Exclusive Property Sale Assignment (Minimum Fee €1,000+VAT)',
      exclusiveAssignment1500: 'Exclusive Property Sale Assignment (Minimum Fee €1,500+VAT)',
      exclusiveAssignment: 'Exclusive Assignment (Greek-English)'
    },
    propertyRent: {
      title: 'Property Rent',
      simpleAssignment: 'Simple Property Rent Assignment',
      exclusiveAssignment: 'Exclusive Property Rent Assignment',
      simpleAssignmentSale: 'Simple Sale Assignment (Greek-English)'
    },
    propertyVisit: {
      title: 'Property Visit',
      visitOrder1000: 'Visit Order (Minimum Fee €1,000+VAT)',
      visitOrder1500: 'Visit Order (Minimum Fee €1,500+VAT)',
      visitOrderInt: 'Visit Order (Greek-English)'
    },
    otherLanguages: {
      title: 'Other Languages (Requires Editing by Each Office)',
      albanian: 'Visit Order (Greek/Albanian)',
      french: 'Visit Order (Greek/French)',
      german: 'Visit Order (Greek/German)',
      spanish: 'Visit Order (Greek/Spanish)',
      italian: 'Visit Order (Greek/Italian)',
      chinese: 'Visit Order (Greek/Chinese)',
      turkish: 'Visit Order (Greek/Turkish)',
      bulgarian: 'Visit Order (Greek/Bulgarian)',
      russian: 'Visit Order (Greek/Russian)',
      serbian: 'Visit Order (Greek/Serbian)'
    },
    manuals: {
      title: 'Manuals - User Guides',
      broker: 'Intrahome Manual for Brokers',
      secretariat: 'Intrahome Manual for Secretariats',
      collaborator: 'Intrahome Manual for Collaborators',
      email: 'Intra-Mail User Guide'
    },
    services: {
      spitogatos: 'Provisioned Advertisement Definition Guide - spitogatos.gr',
      xe: 'Provisioned Advertisement Definition Guide - xe.gr',
      youtube: 'Property Presentation Video Creation Guide - YouTube',
      spaces3d: '3D Spaces (Modern Presentation Media) Price List for Bee real estate Members'
    }
  },
  callList: {
    title: 'Incoming'
  },
  internalMailList: {
    title: 'Emails'
  },
  callDetail: {
    new: 'Create inbox',
    client: 'Υπάρχουσα Επαφή',
    info: 'Σημείωση',
    generalInstructions: 'Σε περίπτωση που επιλέξετε υπάρχουσα επαφή, δεν δίνεται η δυνατότητα προσθήκης νέας επαφής',
    instructions: 'Εάν πρόκειται για νέα επαφή συμπληρώνετε παρακάτω',
    edit: 'Edit inbox',
    clientInfo: 'Contact details',
    callInfo: 'Incoming details',
    createClient: 'Δημιουργία Επαφής',
    notes: 'Incoming notes',
    createUser: 'Creation of user'
  },
  pages: {
    home: 'HOME',
    jobs: 'NEW JOB OPENINGS',
    info: 'INFO',
    blog: 'BLOG',
    favorites: 'FAVORITES',
    franchise: 'FRANCHISE',
    team: 'TEAM',
    our_offices: 'OUR OFFICES',
    search: 'SEARCH'
  },
  personalWebsiteManagement: {
    colors: 'Χρώματα',
    menu: 'Μενού',
    footer: 'Footer',
    js: 'Js',
    css: 'Css',
    footerHtml: 'Footer HTML',
    job_openingsHtml: 'HTML',
    infoHtml: 'HTML',
    section1: '1ο τμήμα',
    section2: '2ο τμήμα',
    section3: '3ο τμήμα',
    section4: '4ο τμήμα',
    section5: '5ο τμήμα',
    section6: '6ο τμήμα',
    general: 'Γενικά',
    primaryColor: 'Κεντρικό Χρώμα',
    backgroundColor: 'Χρώμα Παρασκηνίου',
    mainPage: 'Κεντρική Σελίδα',
    defaultTitle: 'Διαχείριση Ιστοσελίδας',
    title: 'Personal website management',
    social_media: 'Social media',
    page_titles: 'Page titles',
    job_openings: 'New job openings',
    blog: 'Blog',
    info: 'Information',
    my_properties: 'My properties',
    logo: 'Logo'
  },
  collaborationList: {
    incomingList: 'Incoming collaborations',
    outgoingList: 'Outgoing collaborations',
    accept: 'Are you sure you want to accept the collaboration?'
  },
  internalSmsCreate: {
    title: 'Send SMS',
    instructions: "Steps for the SMS service:<br>1) Create an account and select the SMS package on the service <a href='https://www.sms.net.gr' target='_blank'>http://www.sms.net.gr/</a><br>2) Request from the service to send you the Username, Api Password, Api Token for API use<br>3) Once they send them to you by email, you enter them in the relevant fields in your profile and you are ready!",
    content: 'Message Area',
    actualMessage: 'Actual Message',
    charsLeft: 'Characters Remaining',
    smsToSent: 'Total SMS to be Sent',
    credits: 'Credit Charge',
    specificPhone: 'Specific Phone'
  },
  internalMailCreate: {
    title: 'Send Email',
    instructions: 'you can send mail by selecting either from Bee members or from your contacts. You can also select properties and/or image and/or text that will include your mail. Restrictions: you cannot send mail to yourself.',
    sendTo: 'Send to',
    chooseFrom: 'Choose from',
    clients: 'Contacts',
    clients_groups: 'Contact groups',
    users: 'agents',
    users_groups: 'Ομάδες Συνεργατών',
    office: 'The whole office',
    secretariat: 'In the secretariat of the office',
    broker: 'In the office broker',
    company: 'Across the Bee Real Estate',
    offices_thessaloniki: 'Thessaloniki offices',
    offices_athens: 'Athens offices',
    support: 'Support',
    Users_groups: 'Agent groups',
    all_broker: 'To all Brokers',
    all_secretariat: 'To all Secretaries',
    all_collaborator: 'To all Agents'
  },
  recommendationDetail: {
    new: 'Insert recommendation',
    edit: 'Recommendation editing',
    incomingList: 'Incoming collaborations',
    outgoingList: 'Outgoing collaborations',
    client: 'Recommendation contact',
    property: 'Recommendation property',
    details: 'Recommendation details',
    accept: 'Are you sure you want to accept the recommendation?'
  },
  recommendationList: {
    title: 'Recommendations',
    incomingProperty: 'Incoming property',
    outgoingProperty: 'Outgoing property',
    incomingClient: 'Incoming contact',
    outgoingClient: 'Outgoing contact',
    rejectionDialogTitle: 'Rejection of recommendation'
  },
  visitList: {
    title: 'Hints',
    delete: 'The selected hint is about to be permanently deleted. Do you want to take this action?',
    deleteBulk: 'The selected hints are about to be permanently deleted. Do you want to take this action?',
    deleteSuccess: 'The selected hint was successfully deleted!',
    deleteBulkSuccess: 'The selected hints were successfully deleted!',
    selectVisits: 'Select the viewings you want to delete',
    warning: 'Warning',
    download: 'List export/ download',
    create: 'Creation of contact',
    deleteBulkClients: 'Bulk deletion of contacts',
    filters: 'Filters',
    selectClients: 'Select the hints you want to delete'
  },
  searchList: {
    title: 'Searches',
    download: 'List export/ download',
    create: 'Creation of search',
    deleteBulkClients: 'Bulk deletion of searches',
    filters: 'Filters'
  },
  roleList: {
    news: 'Updates',
    auctions: 'Auctions',
    title: 'Roles',
    saveRole: 'Save role',
    addRole: 'New role',
    deleteRole: 'Deletion of role',
    create: 'Creation',
    view: 'Access',
    read: 'Reading',
    read_others: 'Reading of others',
    edit: 'Editing',
    edit_others: 'Editing of others',
    delete: 'Deletion',
    delete_others: 'Deletion of others',
    list: 'List',
    approve: 'Approval',
    list_others: 'List of others',
    properties: 'Properties',
    clients: 'Contacts',
    groups: 'Groups',
    bridge_events: 'Γεγονότα Γέφυρας',
    theme_templates: 'Πρότυπα Θεμάτων',
    blog_posts: 'Αναρτήσεις Blog',
    copyFrom: 'Copy rights from',
    nameRequired: 'The name is required',
    deleteSuccess: 'The role was successfully deleted!',
    saveSuccess: 'The role was successfully saved!',
    roleRequired: 'The role selection is required',
    users: 'Users',
    roles: 'Role management',
    selectAll: 'Add all',
    deselectAll: 'Delete all',
    offices: 'Offices',
    tasks: 'Tasks',
    visits: 'Hints',
    searches: 'Searches',
    notifications: 'Ειδοποιήσεις',
    privileges: 'Privileges',
    recommendations: 'Recommendations',
    emails: 'Emails',
    collaboratorstatistics: 'Agent statistics',
    calls: 'Calls',
    collaborations: 'Collaborations',
    tickets: 'Tickets',
    other_offices_users: 'Access other offices users'
  },
  roles: {
    none: 'None',
    collaborator: 'Agent',
    broker: 'Broker',
    secretariat: 'Secretariat',
    central_secretariat: 'Central Secretariat',
    theme_templates: 'UI Manager',
    paid_package_role: 'Extra paid package',
    super_admin: 'System administrator'
  },
  propertyList: {
    title: 'Properties',
    delete: 'The selected property is about to be permanently deleted. Do you want to take this action?',
    deleteBulk: 'The selected properties are about to be permanently deleted. Do you want to take this action?',
    deleteSuccess: 'The selected property was successfully deleted!',
    deleteBulkSuccess: 'The selected properties were successfully deleted!',
    warning: 'Warning',
    selectProperties: 'Select the properties you want to delete',
    download: 'Property list export/ download',
    create: 'Creation of property',
    copyPropertyContent: 'You are about to create an exact copy. Do you want to proceed?',
    deleteBulkProperties: 'Bulk deletion of properties',
    filters: 'Filters',
    show_to: 'Show to',
    changeOwner: 'Transfer to agent',
    no_services: 'No service'
  },
  visitDetail: {
    visit: 'Hint',
    newVisit: 'Insert hints',
    editVisit: 'Edit hint',
    formErrors: 'The viewing form contains errors!',
    successSave: 'Successfully save of hint',
    formerrons: 'The hint form contains errors!'
  },
  collaborationDetail: {
    new: 'Insert collaboration',
    edit: 'Editing of collaboration',
    waiting: 'Waiting',
    approved: 'Approved',
    rejected: 'Rejected',
    canceled: 'Ακυρώθηκε',
    apiCancel: 'The selected collaboration is about to be permanently canceled. Do you want to take this action?',
    apiCancelSuccess: 'The selected entry was successfully canceled!',
    cancelled: 'Cancelled'
  },
  searchDetail: {
    search: 'Search',
    newSearch: 'Insert Search',
    editSearch: 'Editing of search',
    formErrors: 'The search form contains errors!',
    successSave: 'Successful save of search',
    results: 'Requests',
    activeSearch: 'Active search',
    date_updated: 'Last update',
    price_asc: 'Ascending Price',
    price_desc: 'Descending Price',
    acreage_asc: 'Ascending Acerage',
    acreage_desc: 'Descending Acerage',
    isNewProperty: 'New Property',
    drawMap: 'Set area by clicking',
    startDrawMap: 'Start of Map Design',
    clearDrawMap: 'Cancellation of Map Design',
    newClient: 'Add new client',
    filters: 'Filters',
    closeMap: 'Close Map',
    clearMap: 'Clear Map',
    findWithMap: 'Area search with map'
  },
  clientDetail: {
    newClient: 'Insert cilent',
    editClient: 'Client editing',
    firstName: 'Name',
    form: 'Form',
    additionalInfo: 'Additional Info',
    lastName: 'Surname',
    identityCard: 'Identity Card',
    foundFrom: 'Found from',
    profession: 'Profession',
    mainPhone: 'Phone (landline)',
    secondaryPhone: 'Mobile phone',
    address: 'Street',
    addressNumber: 'Number',
    postCode: 'P.C.',
    age: 'Age',
    mainEmail: 'Email',
    fax: 'Fax',
    taxIdentificationNumber: 'TIN',
    groups: 'Groups',
    notes: 'Notes',
    formErrors: 'The contact form contains errors!',
    isRequired: 'The field is required',
    successSaveClient: 'Successful save of client'
  },
  officeList: {
    title: 'Offices',
    delete: 'The selected office is to be permanently deleted. Do you want to proceed with this action?',
    deleteBulk: 'The selected offices are to be permanently deleted. Do you want to proceed with this action?',
    deleteSuccess: 'The office was successfully deleted!',
    deleteBulkSuccess: 'The selected offices were successfully deleted!',
    warning: 'Warning',
    download: 'List export/download',
    create: 'Office creation',
    deleteBulkOffices: 'Bulk deletion of offices',
    selectOffices: 'Select the offices you want to delete'
  },
  blogPostList: {
    title: 'Blog',
    delete: 'The selected post will be permanently deleted. Do you want to proceed with this action?',
    deleteBulk: 'The selected posts will be permanently deleted. Do you want to proceed with this action?',
    deleteSuccess: 'The post was successfully deleted!',
    deleteBulkSuccess: 'The selected posts were successfully deleted!',
    warning: 'Warning',
    download: 'Export/Download List',
    create: 'Create Post',
    deleteBulkOffices: 'Bulk Delete Posts',
    selectOffices: 'Select the posts you want to delete'
  },
  userList: {
    title: 'Agents',
    delete: 'The selected partner is to be permanently deleted. Do you want to proceed with this action?',
    deleteBulk: 'Selected users are to be permanently deleted. Do you want to proceed with this action?',
    deleteSuccess: 'The user was deleted successfully!',
    deleteBulkSuccess: 'The selected users were deleted successfully!',
    warning: 'Warning',
    selectUsers: 'Select the users you want to delete',
    download: 'List export/download',
    create: 'User creation',
    deleteBulkUsers: 'Bulk deletion of users',
    filters: 'Filters'
  },
  officeDetail: {
    newOffice: 'Office import',
    editOffice: 'Office editing',
    formErrors: 'The form of the office has errors!',
    isRequired: 'The field is required',
    successSave: 'Successful save of office',
    spitogatosUsername: 'Spitogatos-Username',
    spitogatosPassword: 'Spitogatos-Password',
    xeUsername: 'xe-Username',
    xePassword: 'xe-Password',
    xe_agent_id: 'Agent ID',
    xe_auth: 'Auth',
    basicAttributes: 'Basic attributes',
    location: 'Location',
    contactDetails: 'Contact details',
    portalPhone: 'Portal phone',
    companyName: 'Official Company Name',
    certificateNumber: 'Certificate Number',
    realtorsAssociation: 'Realtors Association',
    taxIdentificationNumber: 'TIN',
    publicRevenueOffice: 'Public Revenue Office',
    competentCourt: 'Competent Court'
  },
  blogPostDetail: {
    newBlogPost: 'Create New Post',
    pickDate: 'Select Date',
    editBlogPost: 'Edit Post',
    formErrors: 'The form contains errors!',
    isRequired: 'This field is required',
    successSave: 'Successful saving of the post'
  },
  userDetail: {
    newUser: 'Agent import',
    editUser: 'Agent editing',
    formErrors: 'The user form has errors!',
    isRequired: 'The field is required',
    profile: 'Profile',
    successSave: 'Successful save of the user',
    smsApi: 'SMS Api',
    socialMedia: 'Social media',
    insuranceData: 'Insurance data',
    systemData: 'System data',
    contactDetails: 'Contact details',
    address: 'Address details',
    basicAttributes: 'Basic attributes',
    additionalAttributes: 'Additional attributes'
  },
  groupDetail: {
    newGroup: 'Group import',
    editGroup: 'Group editing',
    formErrors: 'The group form has errors!',
    isRequired: 'The field is required!',
    successSave: 'Successful save of the group'
  },
  privilegeDetail: {
    new: 'Privilege import',
    edit: 'Privilege editing',
    formErrors: 'The privilege form has errors!',
    isRequired: 'The field is required!',
    successSave: 'Successful save of the privilege'
  },
  generatePrivilegesDetail: {
    title: 'Generate Privileges',
    list: 'List of Privileges (Separated by comma)',
    formErrors: 'The privilege form contains errors!',
    isRequired: 'The field is required',
    successSave: 'Successful saving of privileges'
  },
  propertyValidation: {
    available_for: 'Field "Available for" which is under "Basic Information", is mandatory',
    category: 'Field "Category" which is under "Basic Information", is mandatory',
    type: 'Field "Type" which is under "Basic Information", is mandatory',
    floor: 'Field "Floor" which is under "Basic Information", is mandatory',
    state: 'Field "Property Status" which is under "Construction", is mandatory',
    construction_year: 'Field "Construction Year" which is under "Construction", is mandatory',
    energy_class: 'Field "Energy class" which is under "Heating & consumption", is mandatory',
    code: 'Field "Code" which is under "Basic Information", is mandatory',
    price: 'Field "Price" which is under "Basic Information", is mandatory',
    acreage: 'Field "Acreage" which is under "Basic Information", is mandatory',
    client_id: 'Field "Owner" which is under "Basic Information", is mandatory',
    area_id: 'Field "Area" which is under "Location", is mandatory',
    assignation_state: 'Field "Assignation State" which is under "Basic Information", is mandatory',
    field_changes: 'Field has changed',
    images: 'Images have been modified',
    images360: 'Images 360 have been modified',
    levels: 'Field "Levels" which is under "Basic Information", is mandatory',
    e_auction_link: 'Field "E Auction Link" which is under "More Information", is mandatory',
    auction_date: 'Field "Auction Date" which is under "More Information", is mandatory',
    latitude: 'Field "Latitude" which is under "Location", has invalid value',
    longitude: 'Field "Longitude" which is under "Location", has invalid value',
    videos: 'Youtube video have been modified',
    yearValidation: 'The field "{fieldName}" located in the "Construction" tab must be an integer and between 1901 and {currentYear}'
  },
  userValidation: {
    pleaseInputPassword: 'Please input the password',
    pleaseInputPasswordAgain: 'Please input the password again',
    twoInputsDontMatch: 'Two inputs don\'t match!',
    atLeastCharacters: 'Length is at least 5 characters'
  },
  propertyDetail: {
    e_auction_link: 'E-Auction link',
    pickYear: 'Pick year',
    pickDate: 'Pick date',
    newProperty: 'Property import',
    translations: 'Translations',
    dateUpdated: 'Date Updated',
    dateCreated: 'Date Created',
    walkthrouUrl: '3D Walk through url',
    updatedBy: 'Updated By',
    another_client: 'Another client',
    video_instructions: ' Paste the YouTube URL address from the video of the property.',
    revisions: 'Revisions',
    assignationStateError: 'The property must be either {active}, {under_negotiation}, or {under_offer_status}',
    addressNumber: 'Address Number',
    postCode: 'Post Code',
    editFormErrors: 'The fields {fields} are mandatory',
    video: 'Video',
    view_less: 'Less filters',
    view_more: 'More filters',
    confirm: 'SHOW RESULTS',
    min: 'Minimum',
    mediaSelection: 'Drag the file here or click to upload',
    services_history: 'Services history',
    property_traffic: 'Property Traffic',
    max: 'Maximum',
    view: 'View',
    selected: 'Selected',
    live: 'Live',
    notSelected: 'Non-selected',
    services: 'Services',
    under_construction: 'Under construction',
    under_construction_short: 'Under con.',
    property_type: 'Property type',
    type: 'Property type',
    price_range: 'Price',
    successful_entry: 'Successful Entry',
    price_range_selection: 'Price selection',
    sea_distance_title: 'Distance from the sea',
    sea_distance_selection: 'Distance selection',
    framing_title: 'Framing',
    framing_selection: 'Framing selection',
    confirmation_date_title: 'Last update',
    property_type_title: 'Subcategory',
    property_type_selection: 'Property type',
    energy_class_title: 'Energy class',
    energy_class_selection: 'Energy class selection',
    heating_title: 'Heating',
    heating_selection: 'Heating selection',
    construction_year_title: 'Construction year',
    construction_year_selection: 'Construction year selection',
    floor_title: 'Floor',
    floor_selection: 'Floor selection',
    rooms_title: 'Sleep rooms',
    rooms_selection: 'Sleep rooms selection',
    bathrooms_title: 'Bathrooms',
    bathrooms_selection: 'Minimum bathrooms',
    acreage_title: 'Acreage in sqm.',
    acreage_selection: 'Acreage in sqm.',
    characteristics: 'Characteristics',
    sale: 'Sale',
    lease: 'Lease',
    penthouse: 'Penthouse',
    furnished: 'Furnished',
    warehouse: 'Warehouse',
    safety_door: 'Safety Door',
    safetyDoor: 'Safety Door',
    alarm: 'Alarm',
    elevator: 'Elevator',
    garden: 'Garden',
    solar_water_heater: 'Solar water heater',
    parking: 'Parking seat',
    veranda: 'Verandas',
    air_conditioning: 'Air conditioning',
    fireplace: 'Fireplace',
    openness_view: 'Openness view',
    pool: 'Pool',
    power_marketing: 'Power Marketing',
    airy: 'Airy',
    cottage: 'Cottage',
    student: 'Student',
    investment: 'Investment',
    floor_apartment: 'Floor apartment',
    mild_sanitation: 'Mild sanitation',
    funnel: 'Sanitation(funnel)',
    facade: 'Facade',
    suspended_ceiling: 'Suspended ceiling',
    staircase: 'Staircase',
    floor_heating: 'Floor heating',
    neoclassic: 'Neoclassic',
    preserved: 'Preserved',
    pets_allowed: 'Pets allowed',
    night_electricity: 'Night electricity',
    with_equipment: 'With equipment',
    cargo_lift: 'Caergo lift',
    agricultural_use: 'Agricultural use',
    tent: 'Tents',
    screens: 'Screens',
    bright: 'Bright',
    painted: 'Painted',
    double_glazing: 'Double glazing',
    doubleGlazing: 'Double Glazing',
    structural_wiring: 'Structural wiring',
    zoning: 'Zone',
    road_type: 'Access from',
    cable_ready: 'Cable TV',
    satellite_receiver: 'Satellite Antenna',
    short_term_letting: 'Short Term Rental',
    exchange_scheme: 'Exchange Scheme',
    exchange_scheme_percentage: 'Exchange Scheme Rate',
    electricity_type: 'Current',
    unloading_ramp: 'Unloading ramp',
    suitable_for_professional: 'Suitable for professional rooms',
    accessible_for_disabled: 'Accessible for disabled',
    luxury: 'Luxury construction',
    playroom: 'Playroom',
    boiler: 'Boiler',
    attic: 'Attic',
    sea_view: 'Sea/beach view',
    seaView: 'Sea/beach view',
    mountain_view: 'Mountain view',
    mountainView: 'Mountain view',
    park_view: 'Park view',
    corner: 'Corner',
    open_house: 'Open House',
    from: 'From',
    to: 'To',
    none: 'Uninterested',
    facade_length: 'Façade length',
    facadeLength: 'Μήκος πρόσοψης',
    balconies_area: 'Balconies area',
    editProperty: 'Property editing',
    formErrors: 'The form of the property has errors!',
    isRequired: 'The field is required!',
    successSave: 'Successful save of property',
    category: 'Property category',
    availableFrom: 'Available from',
    auctionDate: 'Auction Date',
    availableFor: 'Available for',
    assignationType: 'Assignation type',
    state: 'Property status',
    assignationState: 'Assignation status',
    assignationStart: 'Assignation start',
    assignationFinish: 'Assignation finish',
    offline: 'Offline',
    attributes: 'Attributes',
    location: 'Location',
    basicInformation: 'Basic information',
    media: 'Media',
    auction: 'Auction',
    actions: 'Promoting actions',
    basicAttributes: 'Basic attributes',
    moreAttributes: 'More attributes',
    locationInformation: 'Location information',
    comments: 'Description',
    property_management: 'Property management',
    show_on_spitogatos: 'Property show on spitogatos',
    show_on_xe: 'Property show on XE',
    show_on_plot: 'Property show on Plot',
    rooms: 'Rooms',
    acreage: 'Acreage (sqm.)',
    user: 'User',
    owner: 'Owner',
    newClient: 'New client',
    area: 'Area',
    constructionYear: 'Construction year',
    renovationYear: 'Renovation year',
    addressOnPortals: 'Address for spitogatos',
    addressOnPlot: 'Address for Plot',
    assignationStates: 'Assignation status',
    imageExists: 'The image already exists with this!',
    fileExists: 'The file already exists with this!',
    invalidImageType: 'Invalid image type!',
    invalidFileType: 'Invalid file type!',
    exceedImagesLimit: 'Image limit exceeded!',
    structure_factor: 'Structure factor',
    cover_rate: 'Cover rate',
    slope: 'Slope',
    images: 'Images',
    images360: 'Virtual Tour 360',
    files: 'Files',
    floor: 'Floor',
    levels: 'Levels',
    square_meters: 'sqm.',
    yes: 'Yes',
    no: 'No',
    title: 'Property',
    code: 'Ad code',
    propertyCode: 'Property Code',
    fullCode: 'Full Code',
    bathrooms: 'Bathrooms',
    propertyDesc: 'Description',
    propertyCodeShort: 'Code:',
    subcategory: 'Subcategory',
    renewed: 'Renewed',
    balconiesArea: 'Balconies area',
    shopWindowLength: 'Shop window length',
    hotelBedsNumber: 'Hotel beds number',
    orientation: 'Orientation',
    electricityType: 'Electricity type',
    toilets: 'WC',
    seaDistance: 'Sea distance',
    avg_monthly_maintenance_fees: 'Monthly maintenance fees',
    avgMonthlyMaintenanceFees: 'Monthly maintenance fees',
    floorType: 'Floor type',
    heating: 'Heating',
    framing: 'Framing',
    energyClass: 'Energy class',
    energyEfficiency: 'Energy efficiency',
    officeLocation: 'Office location',
    livingRooms: 'Living rooms',
    kitchens: 'Kitchens',
    price: 'Price',
    status: 'Status',
    description: 'Entry text',
    portalDescription: 'Portal text',
    portal: 'Content for portals',
    contract_copy: 'Contract copy',
    building_permit_copy: 'Building permit copy',
    topographic: 'Topographic',
    coverage_plan: 'Coverage plan',
    floor_plan_copy: 'Floor plan copy',
    ground_floor_plan_copy: 'Ground floor plan copy',
    basement_floor_plan_copy: 'Basement floor plan copy',
    land_registry_copy: 'Land registry copy',
    pea_copy: 'PEA copy',
    electricity_provider_copy: 'Copy of PPC bill',
    water_provider_copy: 'Copy of water provider bill',
    iasve_copy: 'Copy of Earthquake Relief Service of Northern Greece',
    weight_copy: 'Weight copy',
    lease_copy: 'Lease copy',
    banner: 'Banner',
    keys: 'Keys',
    joint_ownership: 'Joint ownership',
    objective_value: 'Objective value',
    record_date: 'Record date',
    mixed_area: 'Mixed area',
    net_area: 'Net area',
    semi_outdoor_area: 'Semi-outdoor area',
    percentage_on_the_plot: 'Percentage on the plot',
    total_building_apartments: 'Total building apartments',
    total_building_floors: 'Total building floors',
    area_per_floor: 'Area per floor (1)',
    network_services: 'Network services',
    offer_date: 'Offer date',
    prepayment_date: 'Prepayment date',
    act_date: 'Act date',
    remuneration_rate: 'Renumeration rate',
    remuneration_amount: 'Renumeration amount',
    apy_number: 'Service Receipt No.',
    cooperation_with: 'In cooperation with',
    kaek: 'KAEK',
    commentsOnProperty: 'Comments on property',
    propertyLocation: 'Property location',
    intramail: 'Intramail',
    intrahome: 'Intrahome',
    previous_step: 'Previous',
    next_step: 'Next',
    completion: 'Completion',
    propertyType: 'Property type',
    propertyAttributes: 'Property attributes',
    site: 'Website',
    collaborator: 'Agent',
    address: 'Address details',
    city: 'City',
    loan_calculator: 'Loan calculator',
    purchase_price: 'Purchase price',
    loan_amount: 'Loan amount',
    suggested_available_capital: 'Suggested available capital',
    years_of_repayment: 'Years of repayment',
    years: 'Years',
    interest_rate: 'Interest rate',
    monthly_installment: 'Monthly installment',
    in_cooperation_with: 'In cooperation with',
    collaborators: 'Agents',
    see_office_properties: 'See office properties',
    see_user_properties: 'See agent properties',
    info: 'Information',
    contact: 'Contact',
    go_to_contact: 'Go to contact',
    phone: 'Phone',
    email: 'Email',
    collaborating_office: 'Collaborating office',
    search_property_code: 'Property search: Enter the code',
    search_user_name: 'Enter the first or last name of the agent',
    search_areas: 'for exmaple Toumpa etc.',
    property_code: 'Property code',
    map_properties: 'Properties selected from the map',
    area_title: 'Area selection',
    construction_year_example: 'for example 2001',
    office_title: 'Office selection',
    all_offices_properties: 'Properties from all offices',
    floor_type_title: 'Floor type',
    floor_type_selection: ' Floor type selection',
    bedrooms: 'Bedrooms',
    special_attributes: 'Special attributes',
    find_more: 'Find more',
    in: 'in',
    map_data: 'There are map date filled in',
    reduced_price: 'Reduced price',
    directTranslation: 'Direct Translation',
    redirectToGoogleTranslate: 'Redirect to Google Translate Page',
    translateAlertTitle: 'Usage Instructions:',
    translateAlertDescription: 'Our platform provides two methods for translation. The \'Direct Translation\' button offers an experimental translation service, which may occasionally be out of service. In such cases, or for more reliable translations, please use the \'Navigate to Google Translate\' button to access Google\'s translation service.'
  },
  media_types: {
    living_room: 'Living Room',
    kitchen: 'Kitchen',
    room: 'Room',
    bathroom: 'Bathroom',
    wc: 'WC',
    hall: 'Hall',
    balcony: 'Balcony',
    view: 'View',
    warehouse: 'Warehouse',
    property_facade: 'Facade',
    interior_view: 'Interior View',
    outdoor_view: 'Outdoor View',
    plot_front: 'Front of Plot',
    plot_area: 'Plot Area',
    top_view: 'Top View',
    opsi: 'Aspect',
    section: 'Section',
    topographic: 'Topographic'
  },
  groupList: {
    title: 'Groups',
    deleteBulk: 'The selected groups are to be permanently deleted. Do you want to proceed with this action?',
    delete: 'The selected group is to be permanently deleted. Do you want to proceed with this action?',
    deleteSuccess: 'The group has been successfully deleted!',
    deleteBulkSuccess: 'The selected groups have been successfully deleted!',
    selectGroups: 'Select the groups you want to delete',
    selectGroup: 'Select Group',
    successSaveGroup: 'Successful save of group',
    download: 'List export',
    create: 'Group creation',
    deleteBulkGroups: 'Bulk deletion of groups',
    saveSelectedToGroup: 'Save selected contacts to the group',
    deleteSelectedFromGroup: 'Delete selected contacts from group'
  },
  privilegeList: {
    title: 'Privileges',
    deleteBulk: 'The selected privilege is to be permanently deleted. Do you want to proceed with this action?',
    delete: 'The selected privilege is to be permanently deleted. Do you want to proceed with this action?',
    deleteSuccess: 'The selected privilege was deleted successfully!!',
    deleteBulkSuccess: 'The selected privileges were deleted successfully!',
    selectGroups: 'Select the privileges you want to delete',
    successSave: 'Successful save of privilege'
  },
  ticket_severity: {
    critical: 'A critical problem with a very big impact',
    major: ' A major problem with important impact',
    minor: 'A minor problem with few impact'
  },
  ticket_status: {
    open: 'Open',
    closed: 'Closed'
  },
  ticket_type: {
    bug: 'Existing functionality problem',
    feature: 'Adding new functionality'
  },
  bridge_statuses: {
    error: 'Error',
    warning: 'Warning',
    info: 'Info',
    success: 'Success'
  },
  dividers: {
    heating_and_consumption: 'Heating & consumption',
    basic_elements: 'Basic elements',
    property_areas: 'Property areas',
    assignation_info: 'Assignation information',
    property_position: 'Exterior places & location of the property',
    technical_features: 'Technical features & interior',
    construction: 'Construction',
    suitable_for: 'Suitable for'
  },
  form: {
    hideOnFront: 'Hide on front',
    themeTemplateEnabled: 'Personal website management active',
    content: 'Content',
    datePublished: 'Ημερομηνία Δημοσίευσης',
    externalProperty: 'Out of office',
    draft: 'Πρόχειρο',
    completed: 'Completed',
    published: 'Published',
    code: 'Code',
    status: 'Status',
    full_code: 'Full code',
    bio: 'CV',
    name: 'Name',
    username: 'Username',
    service: 'Service',
    gemh: 'GEMI',
    date: 'Date',
    recipients: 'Recipients',
    summary: 'Summary',
    tickerTitle: 'Ticket Title',
    recipient: 'Recipient',
    severity: 'Severity',
    sender: 'Sender',
    instructions: 'Instructions',
    mas: 'MAS',
    smsApiToken: 'SMS Api Token',
    smsApiUsername: 'SMS Username',
    smsApiPassword: 'SMS Api Password',
    title: 'Title',
    subject: 'Subject',
    firstName: 'First name',
    pleaseSelect: 'Please select',
    lastName: 'Last name',
    identityCard: 'Identity card',
    foundFrom: 'Found us from',
    profession: 'Profession',
    specificPhone: 'Specific phone',
    phone: 'Phone',
    mainPhone: 'Phone(landline)',
    secondaryPhone: 'Mobile phone',
    address: 'Street',
    addressNumber: 'Number',
    postCode: 'P.C.',
    description: 'Description',
    latitude: 'Latitude',
    longitude: 'Longitude',
    age: 'Age',
    state: 'Status',
    type: 'Type',
    mainEmail: 'Email',
    secondaryEmail: 'Secondary Email',
    fax: 'Fax',
    taxIdentificationNumber: 'TIN',
    groups: 'Groups',
    group: 'Group',
    office: 'Office',
    to: 'Προς',
    user: 'User',
    collaborator: 'Agent',
    area: 'Area',
    areas: 'Selection of multiple areas',
    notes: 'Notes',
    city: 'City',
    role: 'Role',
    roles: 'Roles',
    active: 'Active',
    property: 'Property',
    client: 'Client',
    clients: 'Clients',
    googleCalendar: 'Google calendar',
    completionDate: 'Completion date',
    cooperationDate: 'Cooperation date',
    rejectionReason: 'Rejection reason',
    required: '*',
    password: 'Password',
    isRequired: 'The field is required!',
    atLeastOnePhone: 'Please fill in at least one phone number',
    isVat: 'The VAT number must be exactly 9 digits',
    userOrOfficeRequired: 'Please fill in either user or office',
    linkedin: 'LinkedIn',
    facebook: 'Facebook',
    youtube: 'Youtube',
    properties: 'Properties',
    media: 'Media',
    logo: 'Logo',
    emailLanguage: 'Email language',
    site: 'Website',
    formErrors: 'The form has errors!',
    text: 'Text',
    image: 'Image',
    isEmail: 'Please fill in an exisiting Email'
  },
  social_media: {
    linkedin: 'LinkedIn',
    facebook: 'Facebook',
    instagram: 'Instagram',
    youtube: 'Youtube'
  },
  api: {
    serverError: 'There is an error.',
    entityNotFound: 'The entity is not found ',
    codeAlreadyExists: 'This property code already exists',
    emailAlreadyExists: 'This email already exists'
  },
  navbar: {
    logOut: 'Salir',
    dashboard: 'Panel de control',
    github: 'Github',
    theme: 'Tema',
    size: 'Tamaño global',
    profile: 'Profile'
  },
  slope: {
    plane: 'Plane',
    inclining: 'Inclining',
    amphitheatric: 'Amphitheatric'
  },
  locale: {
    title: 'Language',
    form: {
      el: 'Multilingual fields in Greek',
      en: 'Multilingual fields in English',
      bg: 'Multilingual fields in Bulgarian',
      it: 'Multilingual fields in Italian',
      de: 'Multilingual fields in German',
      es: 'Multilingual fields in Spanish',
      fr: 'Multilingual fields in French',
      he: 'Multilingual fields in Hebrew',
      al: 'Multilingual fields in Albanian',
      ru: 'Multilingual fields in Russian',
      se: 'Multilingual fields in Swedish',
      tr: 'Multilingual fields in Turkish',
      ar: 'Multilingual fields in Arabic',
      cs: 'Multilingual fields in Czech',
      nl: 'Multilingual fields in Dutch',
      pl: 'Multilingual fields in Polish',
      ro: 'Multilingual fields in Romanian',
      sr: 'Multilingual fields in Serbian',
      zh: 'Multilingual fields in Chinese'
    },
    el: 'Greek',
    en: 'English',
    it: 'Italian',
    bg: 'Bulgarian',
    de: 'German',
    es: 'Spanish',
    fr: 'French',
    he: 'Hebrew',
    al: 'Albanian',
    ru: 'Russian',
    se: 'Swedish',
    tr: 'Turkish',
    ar: 'Arabic',
    cs: 'Czech',
    nl: 'Dutch',
    pl: 'Polish',
    ro: 'Romanian',
    sr: 'Serbian',
    zh: 'Chinese'
  },
  login: {
    title: 'Login form',
    logIn: 'Login',
    recoverPassword: 'Recover Password',
    username: 'Username /Email',
    password: 'Password',
    forgotPassword: 'Forgot password!',
    returnToLogin: 'Go to login page!',
    emailSend: 'Email has been send with password recovery steps!',
    authFailure: 'Login failure. Please try again!'
  },
  actions: {
    apiDeleteBulk: 'The selected actions are to be permanently deleted. Do you want to proceed with this action?',
    apiDeleteBulkSuccess: 'The selected actions were successfully deleted!',
    apiDelete: 'The selected action is to be permanently deleted. Do you want to proceed with this action?',
    apiDeleteSuccess: 'The selected action was successfully deleted!',
    apiSend: 'The selected email is about to be sent. Do you want to proceed with this action?',
    apiSendSuccess: 'The selected email was sent successfully!',
    apiSuccessSave: 'Successful save!',
    apiSuccessDelete: 'Successful deletion!',
    apiSuccessSendEmail: 'Email sent successfully!',
    apiSuccessSendSms: 'SMS sent successfully!',
    selectItems: 'Select the actions you want to delete!',
    deleteItemBulk: 'Bulk deletion of selected',
    sendWithSms: 'Send with SMS',
    sentForApproval: 'Send for approval',
    draw: 'Design',
    manageGroups: 'Manage Groups',
    cancel_draw: 'Design cancellation',
    saveDescriptions: 'Save All Descriptions',
    submit_draw: 'Design submission',
    sendToPortals: 'Send to specific portal',
    sendEmail: 'Send email',
    create: 'Creation',
    filters: 'Filters',
    edit: 'Editing',
    approval: 'Approval',
    accept: 'Acceptance',
    reject: 'Rejection',
    delete: 'Deletion',
    cancel: 'Cancellation',
    save: 'Save',
    submit: 'Implementation',
    clear: 'Clear',
    close: 'Close',
    preview: 'Preview',
    back: 'Back',
    change: 'Change',
    completion: 'Completion',
    add: 'Addition',
    results: 'Results',
    warning: 'Warning',
    loadMore: 'Load more',
    transfer: 'Transfer',
    download: 'List download',
    downloadWithClients: 'Export Contacts',
    send: 'Send',
    confirm: 'Confirmation',
    moreActions: 'More actions',
    rejectionDialogTitle: 'Rejection reason ',
    save_property: 'Save',
    remove_from_favorites: 'Removal',
    refresh: 'Refresh',
    search: 'Search'
  },
  filters: {
    title: 'Filters',
    subcategory: 'Subcategory',
    views: 'Views',
    severity: 'Severity',
    property_state: 'Property status',
    results: 'Results',
    show: 'Show',
    foundFrom: 'Found us from',
    profession: 'Profession',
    groups: 'Groups',
    dateCreated: 'Import date',
    offices: 'Offices',
    personal: 'Personal',
    personalProperties: 'Personal properties',
    floor: 'Floor',
    officeUsers: 'Office users',
    activeUsers: 'Active users',
    roles: 'Roles',
    personalClients: 'Personal clients',
    activeProperties: 'Active properties',
    personalSearches: 'Personal searches',
    personalVisits: 'Personal visits',
    activeSearches: 'Active searches',
    availableFor: 'Available for',
    available_from: 'Available from',
    kitchens: 'Kitchens',
    living_rooms: 'Living rooms',
    type: 'Type',
    users: 'Users',
    clients: 'Clients',
    properties: 'Properties',
    category: 'Category',
    offline: 'Offline',
    active: 'Active',
    needsApproval: 'To approval',
    underModification: 'Under modification',
    price: 'Price',
    acreage: 'Acreage',
    areas: 'Areas',
    assignationType: 'Assignation type',
    assignationState: 'Assignation state',
    visitDate: 'Visit date',
    collaborator: 'Agent',
    officeFrom: 'From office',
    officeTo: 'To office',
    collaboratorFrom: 'From agent',
    collaboratorTo: 'To agent',
    personalCollaborations: 'Personal collaborations',
    status: 'Status',
    from: 'From',
    to: 'To'
  },
  table: {
    title: 'Title',
    office: 'Office',
    customer: 'Client',
    auctionSite: 'Site e-Auction',
    message: 'Message',
    user: 'User',
    code: 'Code',
    statistics: 'Statistics',
    importance: 'Importance',
    type: 'Type',
    search: 'Search',
    add: 'Add',
    export: 'Export',
    id: 'ID',
    date: 'Date',
    author: 'User',
    status: 'Status',
    actions: 'Actions',
    sender: 'Sender',
    recipients: 'Recipients',
    subject: 'Subject',
    severity: 'Severity',
    properties: 'Properties',
    edit: 'Editing',
    editOfficePage: 'Edit Office Page',
    changeOwner: 'Move to Agent',
    copyProperty: 'Copy Property',
    delete: 'Deletion',
    cancel: 'Cancellation',
    confirm: 'Confirmation',
    services: 'Services',
    fullName: 'Full name',
    age: 'Age',
    fax: 'Fax',
    active: 'Active',
    inactive: 'Inactive',
    activeSearch: 'Active',
    roles: 'Roles',
    role: 'Role',
    email: 'Email',
    secondaryEmail: 'Secondary Email',
    phone: 'Phone',
    mobilePhone: 'Mobile phone',
    foundFrom: 'Found us from',
    profession: 'Profession',
    dateCreated: 'Import date',
    callDate: 'Call date',
    availableFor: 'Available for',
    dateUpdated: 'Last update',
    datePublished: 'Published Date',
    name: 'Name',
    owner: 'Owner',
    area: 'Area',
    collaborator: 'Agent',
    price: 'Price',
    acreage: 'Acreage',
    field: 'Field',
    floor: 'Floor',
    notes: 'Notes',
    property: 'Property',
    client: 'Client',
    category: 'Category',
    views: 'Views',
    confirmationDate: 'Confirmation date',
    completionDate: 'Completion date',
    squareMetersFrom: 'from sqm.',
    squareMetersTo: 'to sqm.',
    euroPriceFrom: 'from €',
    euroPriceTo: 'to €',
    constructionYearFrom: 'Year from',
    constructionYearTo: 'Year to',
    groups: 'Groups',
    lastLogin: 'Last login',
    percentage: 'Percentage',
    officeFrom: 'From office',
    officeTo: 'To office',
    collaboratorFrom: 'From agent',
    collaboratorTo: 'To agent',
    ika: 'ΙΚΑ',
    site: 'Site',
    intrahome: 'Intrahome',
    intrahomeNewTab: 'Open new tab',
    currentVersion: 'Current price',
    previousVersion: 'Previous price'
  },
  settings: {
    title: 'Page style setting',
    theme: 'Theme Color',
    showTagsView: 'Show Tags-View',
    showSidebarLogo: 'Show Sidebar Logo',
    fixedHeader: 'Fixed Header',
    sidebarTextTheme: 'Sidebar Text Theme'
  },
  search: {
    ignored_properties: 'Ignored Properties',
    title: 'Search by name',
    county: 'County',
    municipality: 'Municipality',
    municipalities: 'Municipalities',
    search_all: 'Search county, municipality',
    areas: 'Areas',
    area: 'Area',
    confirm: 'CONFIRMATION',
    all: 'All',
    no_results: 'No results',
    something_went_wrong: 'Something went wrong'
  },
  search_filters: {
    title: 'Filters'
  },
  search_options: {
    title: 'Select search type',
    search_by_text: 'Search by name',
    search_by_text_descr: 'Type the name of an area to search',
    search_by_draw_map: 'Draw an area on the map',
    search_by_draw_map_descr: 'Draw directly on the map the area you want to search',
    search_by_map_radius: 'Select the center and search radius.',
    search_by_map_radius_descr: 'Look near your location or at a point of your choice'
  },
  home: {
    title: 'Bee real estate',
    start_new_search: 'NEW SEARCH',
    search: 'SEARCH',
    latest_properties: 'Latest properties',
    contact_us: 'Contact us'
  },
  draw_map: {
    title: 'Search on the map',
    draw_info: 'Draw directly on the map the area you want to search',
    confirm: 'CONFIRMATION',
    draw_area: 'DRAW THE AREA'
  },
  radius_map: {
    title: 'Search on the map',
    choose_a_point: 'Choose a point on the map',
    confirm: 'CONFIRMATION',
    meters: 'm.',
    kilometers: 'km.'
  },
  properties_page: {
    no_price: 'Upon contact',
    title: 'Search results',
    month: 'Month',
    square_meters: 'sqm.',
    sort_by_default: 'Sort by default',
    sort_by_price_desc: 'Descending price',
    sort_by_acreage_desc: 'Descending acreage',
    sort_by_most_recent: 'Most recent',
    sort_by_price_asc: 'Ascending price',
    sort_by_price_acreage_asc: 'Ascending acreage',
    sort_by_less_recent: 'Less recent'
  },
  found_from: {
    none: 'None',
    bee_website: 'bee-realestate.gr',
    xe: 'xe.gr',
    spitogatos: 'spitogatos.gr',
    tospitimou: 'tospitimou.gr',
    spiti24: 'spiti24.gr',
    social_networks: 'social',
    internet: 'Internet',
    aggelioforos: 'Aggelioforos',
    newspaper_magazine: 'Newspapers-Magazines',
    banner: 'Banner-Shops- Rentals',
    newsletter: 'Newsletter',
    office_advertising: 'Office advertising',
    open_house: 'Open House',
    office_visitor: 'Office visitor',
    other_office_recommendation: 'Other office recommendation',
    known_recommendation: 'Known recommendation',
    personal_contact: 'Personal contact'
  },
  profession: {
    none: 'Unknown',
    self_employed: 'Self employed',
    civil_servant: 'Civil servant',
    businessman: 'Επιχειρηματίας',
    private_employee: 'Private employee',
    broker: 'Broker',
    contractor: 'Contractor',
    engineer: 'Engineer',
    notary: 'Notary',
    retired: 'Retired',
    layer: 'Lawyer',
    doctor: 'Doctor'
  },
  property_type: {
    none: 'Indifferent',
    apartment: 'Apartment',
    bedsit: 'Bedsit',
    maisonette: 'Maisonette',
    detached_house: 'Detached House',
    villa: 'Villa',
    loft: 'Loft',
    bungalow: 'Bungalow',
    building: 'Building',
    apartment_complex: 'Apartment Complex',
    farm: 'Farm/Ranch',
    houseboat: 'Houseboat',
    multi_level_apartment: 'Multi-level Apartment',
    office: 'Office',
    shop: 'Shop',
    warehouse: 'Warehouse',
    industrial_area: 'Industrial Area',
    craft_space: 'Craft Space',
    hotel: 'Hotel',
    business_building: 'Business Building',
    hall: 'Hall',
    showroom: 'Showroom',
    holding: 'Holding',
    parcel: 'Parcel',
    parcel_within_zone: 'Parcel Within Zone',
    parcel_under_integration: 'Parcel Under Integration',
    island: 'Island',
    parking: 'Parking',
    business: 'Business',
    prefabricated: 'Prefabricated',
    detachable: 'Detachable',
    air: 'Air',
    catering_industry: 'Catering Industry',
    trade: 'Trade',
    services: 'Services',
    other_business: 'Other Businesses',
    other_real_estate: 'Other Real Estate'
  },
  property_category: {
    none: 'Καμία',
    residence: 'Residence',
    business_roof: 'Business roof',
    land: 'Land',
    business: 'Business',
    student: 'Student'
  },
  property_rooms: {
    single_area: 'Single area',
    ten_plus: 'Ten plus'
  },
  property_levels: {
    eighth_and_above: 'eight and above'
  },
  property_bathrooms: {
    fifth_and_above: 'five and above'
  },
  property_toilets: {
    fifth_and_above: 'five and above'
  },
  property_state: {
    title: 'State',
    incomplete: 'Incomplete',
    under_construction: 'Under Construction',
    newly_build: 'Newly build',
    perfect_state: 'Perfect state',
    good_state: 'Good state',
    needs_renovation: ' Needs renovation',
    renovated: 'Renovated',
    partly_renovated: 'Partly renovated'
  },
  rec_status: {
    newly_added: 'Waiting',
    approved: 'Approved',
    rejected: 'Rejected',
    canceled: 'Canceled'
  },
  services: {
    spitogatos: 'Spitogatos',
    xe: 'xe',
    plot: 'Plot'
  },
  property_status: {
    in_process: 'In process (not sent for approval)',
    sent_for_approval: 'It has been sent for approval (no editing can be done)',
    approved: 'The property has been approved',
    changes_required: ' The property has not been approved and changes are required',
    sold_or_rented_by_office: 'Sold/rented by office',
    sold_or_rented_by_owner: 'Sold / rented by owner',
    withdrawn_by_owner: 'Withdrawn by owner',
    expired: 'Expired',
    inactive: 'Inactive',
    needs_transfer: 'The partner is not active! You need to transfer it'
  },
  available_for: {
    title: 'Available for',
    none: 'Unknown',
    sale: 'Sale',
    lease: 'Lease',
    compensation: 'Compensation'
  },
  assignation_state: {
    active: 'Αctive',
    inactive: 'Inactive',
    withdrawn_from_owner: 'Withdrawn from owner',
    under_negotiation: 'Under negotiation',
    under_offer_status: 'Under offer status',
    sold: 'Sold',
    offline: 'Offline',
    sold_from_owner: 'Sold from owner',
    expired: 'Expired',
    inactive_office: 'Inactive office'
  },
  assignation_type: {
    exclusive: 'Exclusive',
    simple: 'Simple'
  },
  floor: {
    none: 'Indifferent',
    below_basement: 'Basement (-2)',
    basement: 'Basement',
    semibasement: 'Semibasement',
    ground_floor: 'Ground floor',
    mezzanine: 'Mezzanine',
    first: '1st',
    second: '2nd',
    third: '3rd',
    fourth: '4th',
    fifth: '5th',
    sixth: '6th',
    seventh: '7th',
    eighth_and_above: '8th and above'
  },
  energy_class: {
    none: 'Indifferent',
    Α_PLUS: 'Α+',
    A: 'Α',
    B_PLUS: 'Β+',
    B: 'Β',
    G: 'G',
    D: 'D',
    E: 'Ε',
    Z: 'Ζ',
    H: 'H',
    exemption: 'Excluded',
    under_issuance: 'Under issuance'
  },
  sea_distance: {
    none: 'Indifferent',
    first_sea_apartment: '1st Sea',
    below_100_meters: '2nd Sea to 100m.',
    between_101_and_500_meters: '101m. up to 500m.',
    above_500_meters: '501m. and up'
  },
  framing: {
    none: 'Indifferent',
    wooden: 'Wooden',
    aluminium: 'Aluminium',
    synthetic: 'Synthetic'
  },
  heating: {
    none: 'None',
    central_oil: 'Central Oil',
    central_gas: 'Central Gas',
    independent_oil: 'Independent Oil',
    independent_gas: 'Independent Gas',
    individual_gas: 'Individual Gas',
    heat_accumulators: 'Heat Accumulators',
    air_condition: 'Air Conditioning',
    heat_pump: 'Heat Pump',
    gas: 'LPG',
    stove: 'Stove',
    pellet: 'Pellet',
    infrared: 'Infrared',
    fan_coil: 'Fan Coil',
    wood: 'Wood',
    teleheating: 'District Heating',
    geothermal_energy: 'Geothermal Energy',
    other: 'Other'
  },
  electricity_type: {
    industrial: 'Industrial',
    single_phase: 'Single Phase',
    three_phase: 'Three Phase'
  },
  road_type: {
    asphalt: 'Asphalt',
    pedestrian: 'Pedestrian',
    paved: 'Paved',
    dirt_road: 'Dirt Road',
    no_road_access: 'No Road Access',
    sea: 'Sea',
    other: 'Other'
  },
  zoning: {
    agricultural: 'Agricultural',
    commercial: 'Commercial',
    industrial: 'Industrial',
    recreational: 'Recreational',
    residential: 'Residential',
    unincorporated: 'Unincorporated'
  },
  confirmation_date: {
    none: 'Indifferent',
    last_twenty_four_hours: 'Last 24 hours',
    last_three_days: 'Last 3 days',
    last_seven_days: 'Last 7 days',
    last_month: 'Last month',
    last_three_months: 'Last 3 months',
    last_six_months: 'Last 6 months',
    last_year: 'Last year'
  },
  orientation: {
    east: 'East',
    east_west: 'East west',
    east_meridian: 'East meridian',
    north: 'North',
    north_east: 'Northeast',
    north_west: 'Northwest',
    west: 'Western',
    west_meridian: 'West meridian',
    meridian: 'Meridian',
    south: 'South',
    south_east: 'Southeast',
    south_west: 'Southwest',
    not_available: 'Not available'
  },
  floor_type: {
    none: 'None',
    marble: 'Marble',
    wood: 'Wood',
    stone: 'Stone',
    laminate: 'Synthetic',
    ceramic_tiles: 'Tile',
    mosaic_tiles: 'Mosaic',
    wood_and_marble: 'Marble/wood',
    marble_and_tile: 'Marble/tile',
    wood_and_stone: 'Stone/wood',
    stone_and_marble: 'Stone/marble',
    wood_and_tile: 'Tile/wood',
    wood_and_mosaic: 'Mosaic/wood',
    industrial: 'Industrial flooring',
    not_available: 'Not available'
  },
  page_builder_links: {
    apartments_for_sale: 'Apartments for sale',
    apartments_for_lease: 'Apartments for lease',
    business_roof_for_sale: 'Business roof for sale',
    business_roof_for_lease: 'Business roof for lease',
    land_for_sale: 'Land for sale',
    land_for_lease: 'Land for lease'
  },
  promotional_actions: {
    update_collaborators_by_phone: 'Update 20 Collaborators by Phone',
    update_profs_by_phone: 'Update 20 Professionals by Phone',
    update_customers_by_letter: 'Customer Registry Update by Letter',
    ad_brochures: 'Advertising Brochures with Features and Photos of the Property, Available in Neighborhood Stores',
    sale_announcement: 'For Sale Announcement on the Office Notice Board',
    newsletter: 'Newsletter',
    open_house: 'Open House',
    update_neighbors_door_to_door: 'Neighborhood Update, with Personal Door-to-Door Visit',
    banner: 'Banner',
    neighbor_posters: 'For Sale Posters in the Neighborhood',
    newspaper_post: 'Color Entry/Advertisement in Newspaper',
    collaborator_notes: 'Collaborator Comments'
  },
  statistics: {
    title: 'Statistics',
    personal_calls: 'Personal Calls',
    visits: 'Viewings',
    inside_office_visits: 'Viewings (Personal/Office)',
    outside_office_visits: 'Outside Office Viewings',
    recommendations: 'Recommendations',
    incoming_recommendations: 'Incoming Recommendations',
    outgoing_recommendations: 'Outgoing Recommendations',
    collaborations: 'Collaborations',
    outgoing_collaborations: 'Outgoing Collaborations',
    incoming_collaborations: 'Incoming Collaborations',
    assignations: 'Assignments',
    properties: 'Properties',
    simple_sale: 'Simple Sale',
    exclusive_sale: 'Exclusive Sale',
    simple_lease: 'Simple Lease',
    exclusive_lease: 'Exclusive Lease',
    active_searches: 'Active Searches',
    rejection_reason: 'Rejection Reason',
    inactive_service: 'Inactive Service'
  },
  property_traffic: {
    location: 'Location',
    visits: 'Visits',
    last_visit: 'Last Visit'
  }
}

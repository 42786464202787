import request from '@/utils/request'
import { exportJson2Excel, prepareExcelHeaders } from '@/utils/excel'
import { formatJson, getTranslations } from '@/utils'

export const getClients = (data: any) =>
  request({
    method: 'post',
    url: '/api/clients/getClients',
    data
  })

export const getClientsCount = (data: any) =>
  request({
    method: 'post',
    url: '/api/clients/getClientsCount',
    data
  })

export const getClient = (params: any) =>
  request({
    method: 'get',
    url: '/api/clients/getClient',
    params
  })

export const saveClient = (data: any) =>
  request({
    method: 'post',
    url: '/api/clients/saveClient',
    data
  })

export const saveClientBulk = (data: any) =>
  request({
    method: 'post',
    url: '/api/clients/saveClientBulk',
    data
  })

export const deleteClient = (params: any) =>
  request({
    method: 'get',
    url: '/api/clients/deleteClient',
    params
  })

export const deleteClientBulk = (data: any) =>
  request({
    method: 'post',
    url: '/api/clients/deleteClientBulk',
    data
  })

export const exportClients = (clients: any[]) => {
  const fields = [
    {
      key: 'first_name',
      type: 'translations',
      transKeys: ['first_name']
    },
    {
      key: 'last_name',
      type: 'translations',
      transKeys: ['last_name']
    },
    { key: 'main_email' },
    { key: 'main_phone' },
    { key: 'secondary_phone' },
    {
      key: 'user',
      type: 'custom',
      parse: (item: any) => {
        return getTranslations(item.user)
      }
    },
    { key: 'date_created', type: 'timestamp' }
  ]

  return exportJson2Excel(
    prepareExcelHeaders(fields),
    formatJson(fields, clients),
    'client-list'
  )
}

import { RouteConfig } from 'vue-router'
import Layout from '@/layout/index.vue'

const chartsRouter: RouteConfig = {
  path: '/collaboration',
  component: Layout,
  redirect: '/collaboration/list',
  meta: {
    title: 'collaboration',
    icon: 'el-icon-collab',
    privileges: ['VIEW_COLLABORATIONS']
  },
  children: [
    {
      path: 'create',
      component: () => import(/* webpackChunkName: "collaboration-create" */ '@/views/collaboration/create.vue'),
      meta: {
        title: 'createCollaboration',
        icon: 'el-icon',
        privileges: ['CREATE_COLLABORATIONS']
      }
    },
    {
      path: 'edit/:id',
      component: () => import(/* webpackChunkName: "collaboration-edit" */ '@/views/collaboration/edit.vue'),
      name: 'EditCollaboration',
      meta: {
        title: 'editCollaboration',
        icon: 'el-icon',
        noCache: true,
        activeMenu: '/collaboration/list',
        hidden: true,
        privileges: ['EDIT_COLLABORATIONS', 'READ_COLLABORATIONS']
      }
    },
    {
      path: 'incoming-list',
      component: () => import(/* webpackChunkName: "collaboration-incoming-list" */ '@/views/collaboration/incomingList.vue'),
      meta: {
        title: 'incomingCollaborationList',
        icon: 'el-icon',
        privileges: ['LIST_COLLABORATIONS']
      }
    },
    {
      path: 'outgoing-list',
      component: () => import(/* webpackChunkName: "collaboration-outgoing-list" */ '@/views/collaboration/outgoingList.vue'),
      meta: {
        title: 'outgoingCollaborationList',
        icon: 'el-icon',
        privileges: ['LIST_COLLABORATIONS']
      }
    }
  ]
}

export default chartsRouter

import request from '@/utils/request'

export const getNotifications = (data: any) =>
  request({
    method: 'post',
    url: '/api/notifications/getNotifications',
    data
  })

export const getMyNotifications = (data: any) =>
  request({
    method: 'post',
    url: '/api/notifications/getMyNotifications',
    data
  })

export const getNotificationsCount = (data: any) =>
  request({
    method: 'post',
    url: '/api/notifications/getNotificationsCount',
    data
  })

export const getNotification = (params: any) =>
  request({
    method: 'get',
    url: '/api/notifications/getNotification',
    params
  })

export const saveNotification = (data: any) =>
  request({
    method: 'post',
    url: '/api/notifications/saveNotification',
    data
  })

export const deleteNotification = (params: any) =>
  request({
    method: 'get',
    url: '/api/notifications/deleteNotification',
    params
  })

export const deleteNotificationBulk = (data: any) =>
  request({
    method: 'post',
    url: '/api/notifications/deleteNotificationBulk',
    data
  })

import { RouteConfig } from 'vue-router'
import Layout from '@/layout/index.vue'

const chartsRouter: RouteConfig = {
  path: '/client',
  component: Layout,
  redirect: '/client/list',
  meta: {
    title: 'client',
    icon: 'el-icon-user-new',
    privileges: ['VIEW_CLIENTS', 'VIEW_GROUPS']
  },
  children: [
    {
      path: 'create',
      component: () => import(/* webpackChunkName: "client-create" */ '@/views/client/create.vue'),
      meta: {
        title: 'createClient',
        icon: 'el-icon',
        privileges: ['CREATE_CLIENTS']
      }
    },
    {
      path: 'edit/:id',
      component: () => import(/* webpackChunkName: "client-edit" */ '@/views/client/edit.vue'),
      name: 'EditClient',
      meta: {
        title: 'editClient',
        icon: 'el-icon',
        noCache: true,
        activeMenu: '/client/list',
        hidden: true,
        privileges: ['EDIT_CLIENTS', 'READ_CLIENTS']
      }
    },
    {
      path: 'list',
      component: () => import(/* webpackChunkName: "client-list" */ '@/views/client/list.vue'),
      meta: {
        title: 'clientList',
        icon: 'el-icon',
        privileges: ['LIST_CLIENTS']
      }
    },
    {
      path: 'group/list',
      component: () => import(/* webpackChunkName: "group-list" */ '@/views/group/list.vue'),
      meta: {
        title: 'groupList',
        icon: 'el-icon',
        privileges: ['LIST_GROUPS']
      }
    }
  ]
}

export default chartsRouter

import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

/* Layout */
import Layout from '@/layout/index.vue'

import clientRouter from './modules/client'
import callRouter from './modules/call'
import visitRouter from './modules/visit'
import officeRouter from './modules/office'
import userRouter from './modules/user'
import propertyRouter from './modules/property'
import roleRouter from './modules/role'
import searchRouter from './modules/search'
import collaborationRouter from './modules/collaboration'
import recommendationRouter from './modules/recommendation'
import supportRouter from './modules/support'
import internalMailRouter from './modules/internalMail'
import blogPostRouter from './modules/blogPost'
import newsRouter from '@/router/modules/news'
Vue.use(VueRouter)

/*
  Note: sub-menu only appear when children.length>=1
  Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
*/

/*
  name:'router-name'             the name field is required when using <keep-alive>, it should also match its component's name property
                                 detail see : https://vuejs.org/v2/guide/components-dynamic-async.html#keep-alive-with-Dynamic-Components
  redirect:                      if set to 'noredirect', no redirect action will be trigger when clicking the breadcrumb
  meta: {
    roles: ['admin', 'editor']   will control the page roles (allow setting multiple roles)
    title: 'title'               the name showed in subMenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon showed in the sidebar
    hidden: true                 if true, this route will not show in the sidebar (default is false)
    alwaysShow: true             if true, will always show the root menu (default is false)
                                 if false, hide the root menu when has less or equal than one children route
    breadcrumb: false            if false, the item will be hidden in breadcrumb (default is true)
    noCache: true                if true, the page will not be cached (default is false)
    affix: true                  if true, the tag will affix in the tags-view
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
*/

/**
  ConstantRoutes
  a base page that does not have permission requirements
  all roles can be accessed
*/
export const constantRoutes: RouteConfig[] = [
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login/index.vue'),
    meta: { hidden: true }
  },
  {
    path: '/forgot-password',
    component: () => import(/* webpackChunkName: "forgot-password" */ '@/views/login/forgot-password.vue'),
    meta: { hidden: true }
  },
  {
    path: '/404',
    component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue'),
    meta: { hidden: true }
  },
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: 'dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/index.vue'),
        name: 'Dashboard',
        meta: {
          title: 'dashboard',
          icon: 'el-icon-odometer',
          affix: true
        }
      }
    ]
  }
]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
*/
export const asyncRoutes: RouteConfig[] = [
  clientRouter,
  propertyRouter,
  callRouter,
  searchRouter,
  officeRouter,
  userRouter,
  visitRouter,
  recommendationRouter,
  collaborationRouter,
  blogPostRouter,
  supportRouter,
  internalMailRouter,
  roleRouter,
  newsRouter,
  {
    path: '/profile',
    component: Layout,
    redirect: '/profile/index',
    children: [
      {
        path: 'index',
        component: () => import(/* webpackChunkName: "example-create" */ '@/views/user/profile.vue'),
        name: 'profile',
        meta: {
          title: 'userProfile',
          icon: 'el-icon-users-new',
          noCache: true
        }
      }
    ]
  },
  {
    path: '/change-password',
    component: Layout,
    meta: { hidden: true },
    redirect: '/change-password/index',
    children: [
      {
        path: 'index',
        component: () => import(/* webpackChunkName: "example-create" */ '@/views/user/change-password.vue'),
        name: 'ChangePassword',
        meta: {
          title: 'changePassword',
          icon: 'el-icon-users-new',
          noCache: true
        }
      }
    ]
  },
  {
    path: '/customize',
    component: Layout,
    redirect: '/customize/page',
    meta: {
      privileges: ['CREATE_THEME_TEMPLATES', 'EDIT_THEME_TEMPLATES']
    },
    children: [
      {
        path: 'page',
        component: () => import(/* webpackChunkName: "example-create" */ '@/views/office/office-page.vue'),
        name: 'profile',
        meta: {
          title: 'editOfficePage',
          icon: 'el-icon-edit-page',
          noCache: true
        }
      }
    ]
  },
  {
    path: '*',
    redirect: '/404',
    meta: { hidden: true }
  }
]

const createRouter = () => new VueRouter({
  // mode: 'history',  // Disabled due to Github Pages doesn't support this, enable this if you need.
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  base: process.env.BASE_URL,
  routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  (router as any).matcher = (newRouter as any).matcher // reset router
}

export default router

import { RouteConfig } from 'vue-router'
import Layout from '@/layout/index.vue'

const chartsRouter: RouteConfig = {
  path: '/internal-mail',
  component: Layout,
  redirect: '/internal-mail/list',
  meta: {
    title: 'internalMail',
    icon: 'el-icon-mail',
    privileges: ['VIEW_EMAILS']
  },
  children: [
    {
      path: 'create',
      component: () => import(/* webpackChunkName: "internal-mail-create" */ '@/views/internal-mail/create.vue'),
      meta: {
        title: 'internalMailCreate',
        icon: 'el-icon'
      }
    },
    {
      path: 'edit/:id',
      component: () => import(/* webpackChunkName: "office-edit" */ '@/views/internal-mail/edit.vue'),
      meta: {
        title: 'editOffice',
        icon: 'el-icon',
        noCache: true,
        activeMenu: '/internal-mail/list',
        hidden: true,
        privileges: ['EDIT_EMAILS']
      }
    },
    {
      path: 'view/:id',
      component: () => import(/* webpackChunkName: "office-edit" */ '@/views/internal-mail/view.vue'),
      meta: {
        title: 'editOffice',
        icon: 'el-icon',
        noCache: true,
        activeMenu: '/internal-mail/list',
        hidden: true,
        privileges: ['EDIT_EMAILS']
      }
    },
    {
      path: 'list',
      component: () => import(/* webpackChunkName: "internal-mail-list" */ '@/views/internal-mail/list.vue'),
      meta: {
        title: 'internalMailList',
        icon: 'el-icon'
      }
    },
    {
      path: 'https://email.rackspace.com',
      meta: {
        title: 'webmail',
        icon: 'el-icon'
      }
    }
  ]
}

export default chartsRouter

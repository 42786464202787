import i18n, { getLocale } from '@/lang'
import {
  assignationStates,
  assignationTypes,
  availableForOptions,
  categories,
  propertyStatuses,
  states,
  types
} from '@/utils/property'
import { UserModule } from '@/store/modules/user'
import { getClients } from '@/api/clients'
import { getProperties } from '@/api/properties'
import { getRoles } from '@/api/roles'
import { getAreas } from '@/api/areas'
import { getOffices } from '@/api/offices'
import { capitalizeFirstLetter } from '@/utils/index'
import { getUsersLight } from '@/api/helpers'

export interface FilterOption {
  // eslint-disable-next-line camelcase
  ignore_case: boolean
}

export enum SortOrder {
  asc = 'asc',
  desc = 'desc',
}

export enum FilterType {
  operator= 'operator',
  field= 'field',
  function= 'function'
}

export enum GeometryType {
  point = 'Point',
  polygon = 'Polygon'
}

export interface Geometry {
  type: GeometryType
  coordinates: []
}

export interface SortProperty {
  field: string
  order: SortOrder
}
/* eslint-disable camelcase */
/* eslint-disable camelcase */
export enum FilterOperator {
  ne= 'ne',
  eq= 'eq',
  lt= 'lt',
  gt= 'gt',
  le= 'le',
  ge= 'ge',
  contains= 'contains',
  list_includes= 'list_includes',
  list_not_includes= 'list_not_includes',
  part_of_list = "part_of_list" ,
  not_part_of_list = "not_part_of_list" ,
  starts= 'starts',
  ends= 'ends',
  and= 'and',
  or= 'or',
  not= 'not',
  with_in= 'with_in',
  intersects= 'intersects',
  overlaps= 'overlaps',
  touches= 'touches',
  disjoint= 'disjoint',
  not_empty= 'not_empty'
}

export interface FilterItem {
  type?: FilterType
  key?: string
  value?: any
  geometry?: Geometry
  options?: FilterOption
  operator?: FilterOperator
  items?: Array<FilterItem>
}

export interface CollectionFiltersQueryPagination {
  fetch_all?: boolean
  page_size?: number
  page?: number
}

export interface CollectionFiltersQuery {
  ids?: string[]
  pagination?: CollectionFiltersQueryPagination
  sort?: SortProperty[]
  filters?: FilterItem
}

export interface CollectionRequestMeta {
  query?: CollectionFiltersQuery
  locale?: string
  options?: any
}

export const getSearchFilter = (value: string, fields: any[]) => {
  return {
    type: FilterType.operator,
    operator: FilterOperator.or,
    items: fields.map((field: any) => {
      let source
      if (typeof field === 'string') {
        source = {
          key:
          field
        }
      } else {
        source = field
      }
      return Object.assign({
        type: FilterType.field,
        value: value,
        operator: FilterOperator.contains
      }, source)
    })
  }
}

export const getIdsFilter = (ids: string[], field: string) => {
  return {
    type: FilterType.operator,
    operator: FilterOperator.or,
    items: ids.map((item) => {
      return {
        type: FilterType.field,
        key: field,
        value: item,
        operator: FilterOperator.eq
      }
    })
  }
}

export const getSortItems: any = (params: any) => {
  if (!params.order || !params.prop) {
    return []
  }

  if (params.prop.indexOf(',') > -1) {
    return params.prop.split(',').map((field: string) => {
      return {
        field: field,
        order: params.order === 'ascending' ? SortOrder.asc : SortOrder.desc
      }
    })
  }

  return [{
    field: params.prop,
    order: params.order === 'ascending' ? SortOrder.asc : SortOrder.desc
  }]
}

const isNumeric = (str: string) => {
  try {
    if (typeof str !== 'string') return false
    const isNumeric = !isNaN(Number(str)) && !isNaN(parseFloat(str))
    return isNumeric
  } catch (err) {
    return false
  }
}

export const getFilterItems: any = (filters: any, search: any) => {
  const items: any = []
  if (search && search.value) {
    items.push(getSearchFilter(
      search.value,
      search.fields
    ))
  }

  filters.forEach((filter: any) => {
    switch (filter.component) {
      case 'SingleSelectFilter': {
        if (filter.modelValue || filter.modelValue === 0) {
          items.push({
            type: FilterType.field,
            key: filter.key,
            value: filter.modelValue,
            operator: FilterOperator.eq
          })
        }
        break
      }
      case 'MultiSelectFilter': {
        if (filter.modelValue && filter.modelValue.length) {
          const keys = filter.key.split(',')
          const orbj: any = {
            type: FilterType.operator,
            operator: FilterOperator.or,
            items: []
          }
          keys.forEach((key: string) => {
            filter.modelValue.forEach((value: string) => {
              orbj.items.push({
                type: FilterType.field,
                operator: FilterOperator.eq,
                value,
                key
              })
            })
          })
          items.push(orbj)
        }
        break
      }
      case 'SwitchStateFilter': {
        if (filter.modelValue) {
          if (filter.custom) {
            filter.getValue(items)
          } else {
            items.push({
              type: FilterType.field,
              key: filter.key,
              value: filter.getValue ? filter.getValue() : filter.modelValue,
              operator: FilterOperator.eq
            })
          }
        }
        break
      }
      case 'StackedFieldsFilter': {
        if (filter.from) {
          items.push({
            type: FilterType.field,
            key: filter.key,
            value: filter.type === 'double' ? parseFloat(filter.from).toFixed(2) : parseInt(filter.from),
            operator: FilterOperator.ge
          })
        }
        if (filter.to) {
          items.push({
            type: FilterType.field,
            key: filter.key,
            value: filter.type === 'double' ? parseFloat(filter.to).toFixed(2) : parseInt(filter.to),
            operator: FilterOperator.le
          })
        }
        break
      }
      case 'DateRangeFilter': {
        if (filter.from) {
          items.push({
            type: FilterType.field,
            key: filter.key,
            value: Date.parse(filter.from),
            operator: FilterOperator.ge
          })
        }
        if (filter.to) {
          items.push({
            type: FilterType.field,
            key: filter.key,
            value: Date.parse(filter.to),
            operator: FilterOperator.le
          })
        }
        break
      }
      default: {
        break
      }
    }
  })
  return items
}

export const createFilter = (items?: any[], options: any = {}) => {
  return {
    query: {
      ids: [],
      filters: {
        type: 'operator',
        operator: 'and',
        items: items || []
      },
      sort: options.sort || [],
      pagination: {
        page_size: options.page_size || 20,
        page: options.page || 1
      }
    },
    locale: getLocale(),
    options: options.options || null
  } as CollectionRequestMeta
}

export const prepareListFilters = (options: any) => {
  const listQuery = options.listQuery
  if (!listQuery.query) return

  if (options.withDefaultSort && !listQuery.query?.sort?.length) {
    listQuery.query.sort = options.defaultSort || [{
      field: 'dateCreated',
      order: SortOrder.desc
    }]
  }

  if (listQuery.query?.filters) {
    listQuery.query.filters.items = [
      ...getFilterItems(options.filters || [], {
        value: options.search || '',
        fields: options.searchFields || []
      })
    ]
  }

  options.callback && options.callback(listQuery)
}

export const multiSelectFilter = (params: any = {}) => {
  return {
    lazy: params.lazy,
    component: 'MultiSelectFilter',
    modelValue: params.modelValue || [],
    key: params.key,
    label: i18n.t(params.label),
    itemText: params.itemText || 'title',
    searchFields: params.searchFields,
    apiEndpoint: params.apiEndpoint,
    showOn: params.showOn || null
  }
}

export const dateRangeFilter = (params: any = {}) => {
  return {
    component: 'DateRangeFilter',
    key: params.key,
    label: i18n.t(params.label),
    from: params.from || '',
    to: params.to || '',
    showOn: params.showOn || null
  }
}

export const singleSelectFilter = (params: any = {}) => {
  return {
    component: 'SingleSelectFilter',
    label: i18n.t(params.label),
    lowerCase: params.lowerCase,
    key: params.key,
    modelValue: params.modelValue || null,
    transKey: params.transKey,
    list: params.list,
    showOn: params.showOn || null
  }
}

export const switchStateFilter = (params: any = {}) => {
  return {
    component: 'SwitchStateFilter',
    key: params.key,
    label: i18n.t(params.label),
    modelValue: params.modelValue || false,
    getValue: params.getValue,
    custom: params.custom || false,
    showOn: params.showOn || null
  }
}

export const stackedFieldsFilter = (params: any = {}) => {
  return {
    component: 'StackedFieldsFilter',
    label: i18n.t(params.label),
    type: params.type,
    key: params.key,
    from: undefined,
    to: undefined,
    showOn: params.showOn || null
  }
}

export const dateFilter = (params: any = {}) => {
  return dateRangeFilter({
    key: params.key || 'date_created',
    label: params.label || 'filters.dateCreated',
    from: params.from || null,
    to: params.to || null
  })
}

export const usersFilter = (params: any = {}) => {
  return multiSelectFilter({
    lazy: true,
    key: params.key || 'user_id',
    label: params.label || 'filters.collaborator',
    itemText: ['first_name', 'last_name'],
    searchFields: [{
      type: 'function',
      key: 'translations.first_name,translations.last_name',
      function_name: 'concat',
      options: {
        ignore_case: true,
        unaccent: true
      }
    }, {
      type: 'function',
      key: 'translations.last_name,translations.first_name',
      function_name: 'concat',
      options: {
        ignore_case: true,
        unaccent: true
      }
    }],
    apiEndpoint: getUsersLight
  })
}

export const officesFilter = (key = 'user_id', trans = 'filters.offices') => {
  return {
    component: 'MultiSelectFilter',
    modelValue: [],
    key: key,
    label: i18n.t(trans),
    itemText: ['name'],
    searchFields: [{
      key: 'code'
    }],
    apiEndpoint: getOffices
  }
}

export const areasFilter = (key = 'area.id') => {
  return {
    lazy: true,
    component: 'MultiSelectFilter',
    key: key,
    itemText: ['title', 'description'],
    customLabel: (area: any) => {
      return `${area.title} (${area.description})`
    },
    modelValue: [],
    label: i18n.t('filters.areas'),
    searchFields: [{
      key: 'translations.title',
      options: {
        ignore_case: true,
        unaccent: true
      }
    }, {
      key: 'translations.description',
      options: {
        ignore_case: true,
        unaccent: true
      }
    }],
    apiEndpoint: getAreas
  }
}

export const rolesFilter = (key = 'roles.id') => {
  return {
    component: 'MultiSelectFilter',
    modelValue: [],
    key: key,
    label: i18n.t('filters.roles'),
    itemText: 'name',
    customLabel: (role: any) => {
      return i18n.t('roles.' + role.name.toLowerCase())
    },
    apiEndpoint: getRoles
  }
}

export const clientsFilter = () => {
  return multiSelectFilter({
    lazy: true,
    key: 'client_id',
    label: 'filters.clients',
    itemText: ['first_name', 'last_name'],
    searchFields: [{
      type: 'function',
      key: 'translations.first_name,translations.last_name',
      function_name: 'concat',
      options: {
        ignore_case: true,
        unaccent: true
      }
    }, {
      type: 'function',
      key: 'translations.last_name,translations.first_name',
      function_name: 'concat',
      options: {
        ignore_case: true,
        unaccent: true
      }
    }],
    apiEndpoint: getClients
  })
}

export const propertiesFilter = (key = 'property_id') => {
  return {
    lazy: true,
    component: 'MultiSelectFilter',
    modelValue: [],
    key: key,
    itemText: 'full_code',
    label: i18n.t('filters.properties'),
    searchFields: [{
      key: 'full_code'
    }],
    apiEndpoint: getProperties
  }
}

export const availableForFilter = (modelValue: any = null) => {
  return {
    component: 'SingleSelectFilter',
    label: i18n.t('filters.availableFor'),
    key: 'available_for',
    modelValue: modelValue || null,
    transKey: 'available_for',
    list: availableForOptions
  }
}

export const propertyStateFilter = (modelValue: any = null) => {
  return {
    component: 'SingleSelectFilter',
    label: i18n.t('filters.property_state'),
    key: 'state',
    modelValue: modelValue || null,
    transKey: 'property_state',
    list: states
  }
}

export const propertyStatusFilter = (modelValue: any = null) => {
  return {
    component: 'SingleSelectFilter',
    label: i18n.t('filters.status'),
    key: 'status',
    modelValue: modelValue || null,
    transKey: 'property_status',
    list: propertyStatuses
  }
}

export const propertyCategoryFilter = (modelValue: any = null) => {
  return {
    component: 'SingleSelectFilter',
    label: i18n.t('filters.category'),
    key: 'category',
    modelValue: modelValue || null,
    transKey: 'property_category',
    list: categories.map(category => category.key)
  }
}

export const propertyTypeFilter = (modelValue: any = null) => {
  return {
    component: 'SingleSelectFilter',
    label: i18n.t('filters.subcategory'),
    key: 'type',
    modelValue: modelValue || null,
    transKey: 'property_type',
    list: types.map(type => type.key)
  }
}

export const assignationTypeFilter = (modelValue: any = null) => {
  return {
    component: 'SingleSelectFilter',
    label: i18n.t('filters.assignationType'),
    key: 'assignation_type',
    modelValue: modelValue || null,
    transKey: 'assignation_type',
    list: assignationTypes
  }
}

export const assignationStateFilter = (modelValue: any) => {
  return {
    simple: true,
    component: 'MultiSelectFilter',
    modelValue: modelValue || [],
    key: 'assignation_state',
    list: assignationStates,
    label: i18n.t('filters.assignationState'),
    transKey: 'assignation_state'
  }
}

export const personalFilter = (params: any = {}) => {
  return switchStateFilter({
    key: params.key,
    label: params.label,
    showOn: params.showOn,
    getValue: () => {
      return UserModule.id
    }
  })
}

export const boolFilter = (key: string, trans: string) => {
  return {
    component: 'SwitchStateFilter',
    label: i18n.t(trans),
    modelValue: false,
    key: key
  }
}

export const clearFilters = (filters: any) => {
  filters.forEach((filter: any) => {
    switch (filter.component) {
      case 'SingleSelectFilter': {
        filter.modelValue = ''
        break
      }
      case 'SwitchStateFilter': {
        filter.modelValue = false
        break
      }
      case 'MultiSelectFilter': {
        filter.modelValue = []
        break
      }
      case 'StackedFieldsFilter': {
        filter.from = undefined
        filter.to = undefined
        break
      }
      case 'DateRangeFilter': {
        filter.from = ''
        filter.to = ''
        break
      }
    }
  })
}

export const addOfficeFilters = (val: string) => {
  return {
    filter: officesFilter(
      `office_id_${val}`,
      `filters.office${capitalizeFirstLetter(val)}`
    ),
    search: ignoreCaseSearchField(`office_${val}.name`)
  }
}

export const addUserFilters = (val: string) => {
  return {
    filter: usersFilter({
      key: `user_id_${val}`,
      label: `filters.collaborator${capitalizeFirstLetter(val)}`
    }),
    search: searchFieldConcat(`user_${val}.`)
  }
}

export const searchFieldConcat = (val = '') => {
  return {
    type: 'function',
    key: `${val}translations.first_name,${val}translations.last_name`,
    function_name: 'concat',
    options: {
      ignore_case: true,
      unaccent: true
    }
  }
}

export const searchFieldConcatReverse = (val = '') => {
  return {
    type: 'function',
    key: `${val}translations.last_name,${val}translations.first_name`,
    function_name: 'concat',
    options: {
      ignore_case: true,
      unaccent: true
    }
  }
}

export const ignoreCaseSearchField = (key = '') => {
  return {
    key: key,
    options: {
      ignore_case: true,
      unaccent: true
    }
  }
}

export const simpleSearchField = (key = '') => {
  return {
    key: key
  }
}

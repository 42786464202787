/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'example': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<path pid="0" d="M96.3 57.5h31.4A64.2 64.2 0 0070.3 0v31.4a32.9 32.9 0 0126 26zm-38.8-26V0A64.2 64.2 0 000 57.5h31.4a32.9 32.9 0 0126-26zm12.8 64.8v31.4A64.5 64.5 0 00128 70H96.6a33.6 33.6 0 01-26.3 26.3zm-38.8-26H0A64.5 64.5 0 0057.8 128V96.6a33.6 33.6 0 01-26.3-26.3z"/>'
  }
})

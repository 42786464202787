import { RouteConfig } from 'vue-router'
import Layout from '@/layout/index.vue'

const chartsRouter: RouteConfig = {
  path: '/user',
  component: Layout,
  redirect: '/user/list',
  meta: {
    title: 'user',
    icon: 'el-icon-partners',
    privileges: ['VIEW_USERS']
  },
  children: [
    {
      path: 'create',
      component: () => import(/* webpackChunkName: "user-create" */ '@/views/user/create.vue'),
      meta: {
        title: 'createUser',
        icon: 'el-icon',
        privileges: ['CREATE_USERS']
      }
    },
    {
      path: 'edit/:id',
      component: () => import(/* webpackChunkName: "user-edit" */ '@/views/user/edit.vue'),
      name: 'EditUser',
      meta: {
        title: 'editUser',
        icon: 'el-icon',
        noCache: true,
        activeMenu: '/user/list',
        hidden: true,
        privileges: ['EDIT_USERS']
      }
    },
    {
      path: 'change-password/:id',
      component: () => import(/* webpackChunkName: "example-create" */ '@/views/user/change-password.vue'),
      name: 'ChangePassword',
      meta: {
        title: 'editUser',
        icon: 'el-icon',
        noCache: true,
        activeMenu: '/user/list',
        hidden: true,
        privileges: ['EDIT_USERS']
      }
    },
    {
      path: 'statistics/:id',
      component: () => import(/* webpackChunkName: "user-edit" */ '@/views/dashboard/index.vue'),
      name: 'UserStatistics',
      meta: {
        title: 'userStatistics',
        icon: 'el-icon',
        noCache: true,
        activeMenu: '/user/list',
        hidden: true,
        privileges: ['EDIT_USERS']
      }
    },
    {
      path: 'list',
      component: () => import(/* webpackChunkName: "user-list" */ '@/views/user/list.vue'),
      meta: {
        title: 'userList',
        icon: 'el-icon',
        privileges: ['LIST_USERS']
      }
    }
  ]
}

export default chartsRouter

import request from '@/utils/request'
import { createFilter, getSearchFilter } from '@/utils/filter'

export const getAreas = (data: any) =>
  request({
    method: 'post',
    url: '/api/areas/getAreas',
    data
  })

export const getAreasAndMunicipalities = async(params: any) => {
  const { data } = await request({
    method: 'get',
    url: '/api/areas/getAreasAndMunicipalities',
    params: params
  })
  return data
}

export const getAreasOrMunicipalitiesById = (data: any) =>
  request({
    method: 'post',
    url: '/api/areas/getAreasOrMunicipalitiesById',
    data
  })

export const getRemoteAreas = async(query: string, items: any) => {
  try {
    if (!query) return []
    const { data } = await getAreas(createFilter([getSearchFilter(
      query, [{
        key: 'translations.title',
        options: {
          ignore_case: true,
          unaccent: true
        }
      }, {
        key: 'translations.description',
        options: {
          ignore_case: true,
          unaccent: true
        }
      }]
    ), ...items]))
    return data.collection
  } catch (err) {
    return []
  }
}

import { getLanguage } from '@/utils/cookies'
import { UserModule } from '@/store/modules/user'
import { MessageBox, Message, Form } from 'element-ui'
import i18n from '@/lang'
import { TimeRange } from '@/models/TimeRange'
import {
  businessAttributes,
  landAttributes,
  residenceAttributes
} from '@/utils/property'

export const locales = [
  'el', // Greek
  'en', // English
  'it', // Italian
  'bg', // Bulgarian
  'de', // German
  'es', // Spanish
  'fr', // French
  'he', // Hebrew
  'al', // Albanian
  'ru', // Russian
  'se', // Swedish
  'tr', // Turkish
  'ar', // Arabic
  'cs', // Czech
  'nl', // Dutch
  'pl', // Polish
  'ro', // Romanian
  'sr', // Serbian
  'zh' // Chinese
]

export const debounce = (fn: any, timer = 1000) => {
  let timeout: any = null

  const debounced = () => {
    const later = () => {
      timeout = null
      fn()
    }

    clearTimeout(timeout)
    timeout = setTimeout(later, timer)

    if (!timeout) {
      fn()
    }
  }

  debounced.cancel = () => {
    clearTimeout(timeout)
    timeout = null
  }

  return debounced
}

export const getTranslation = (item: any, key: string): any => {
  if (!item) return
  if (!item.translations) return item[key] || ''
  if (item.translations.length === 0) return item[key] || ''

  let translation
  let locale = getLanguage()
  try {
    translation = item.translations.filter((t: { locale: string | undefined }) => t.locale === locale)[0][key]
  } catch (e) {
    try {
      locale = 'el'
      translation = item.translations.filter((t: { locale: string | undefined }) => t.locale === locale)[0][key]
    } catch (e) {
      translation = item.translations[0][key]
    }
  }
  return translation || item[key] || ''
}

export const getTimestamp = (value: string) => {
  return new Date(value).getTime()
}

export const convertToMapTranslations = (translations: any = [], TranslationModel: any) => {
  return translations.concat(locales.map(locale => ({ locale })))
    .reduce(function(previousValue: any, currentValue: any) {
      previousValue[currentValue.locale] = previousValue[currentValue.locale] || new TranslationModel(currentValue)
      return previousValue
    }, {})
}

export const convertToArrayTranslations = (translations: any, TranslationModel: any) => {
  return Object.keys(translations).map((key) => {
    return new TranslationModel(translations[key])
  })
}

export const getTranslations = (item: any, keys = ['first_name', 'last_name']) => {
  if (!item || !keys) return ''
  return keys.map((key) => {
    return getTranslation(item, key)
  }).join(' ')
}

export const getAreaFullTitle = (params: any = {}) => {
  const withAddress = params.withAddress || false
  const property = params.property
  const areaArr: string[] = []
  const addressArr: string[] = []

  if (withAddress) {
    if (property.address) {
      addressArr.push(property.address)
    }
    if (property.address_number) {
      addressArr.push(property.address_number)
    }
    if (property.post_code) {
      addressArr.push(property.post_code)
    }
  }

  if (property.area && property.area.title) {
    areaArr.push(property.area.title)
  } else if (property.area_title) {
    areaArr.push(property.area_title)
  } else if (property.area) {
    areaArr.push(getTranslations(property.area, ['title']))
  }

  if (property.municipality && property.municipality.title) {
    areaArr.push('(' + property.municipality.title + ')')
  } else if (property.municipality_title) {
    areaArr.push('(' + property.municipality_title + ')')
  } else if (property.area && property.area.municipality) {
    areaArr.push(getTranslations(property.area.municipality, ['title']))
  }

  if (areaArr.length === 0 && addressArr.length === 0) {
    return ''
  }

  return `${areaArr.join(' ')} ${addressArr.join(', ')}`
}

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const parseTime = (
  time?: object | string | number | null,
  cFormat?: string
): string | null => {
  if (time === undefined || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d}'
  let date: Date
  if (typeof time === 'object') {
    date = time as Date
  } else {
    if (typeof time === 'string') {
      if (/^[0-9]+$/.test(time)) {
        time = parseInt(time)
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(/-/gm, '/')
      }
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj: { [key: string]: number } = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  return format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    return value.toString().padStart(2, '0')
  })
}

export const stripHtml = (html: any) => {
  const tmp = document.createElement('DIV')
  tmp.innerHTML = html
  return tmp.textContent || tmp.innerText || ''
}

export const hasPermission = (privileges: string[] = []) => {
  if (privileges.length) {
    return UserModule.roles.some((role: any) => {
      return role.privileges.some((privilege: any) => {
        return privileges.includes(privilege.name)
      })
    })
  } else {
    return true
  }
}

export const hasRoles = (roles: string[] = [], userRoles: any[] = UserModule.roles) => {
  if (roles.length) {
    return userRoles.some((role: any) => {
      return roles.includes(role.name.toLowerCase())
    })
  } else {
    return true
  }
}

// Format and filter json data using filterKeys array
export const formatJson = (filterKeys: any, jsonData: any) =>
  jsonData.map((data: any) => filterKeys.map((item: any) => {
    if (item.type === 'timestamp') {
      return parseTime(data[item.key])
    } else if (item.type === 'integer') {
      if (!data[item.key]) {
        return ''
      }
      return parseInt(data[item.key]) || ''
    } else if (item.type === 'double') {
      if (!data[item.key]) {
        return ''
      }
      return parseFloat(data[item.key]).toFixed(2) || ''
    } else if (item.type === 'translations') {
      return getTranslations(data, item.transKeys)
    } else if (item.type === 'custom') {
      return item.parse(data)
    } else if (item.type === 'meta') {
      return item.meta[item.key]
    } else {
      return data[item.key]
    }
  }))

export const splitArrayIntoChunksOfLen = (arr: [], len: number) => {
  const chunks = []
  const n = arr.length
  let i = 0
  while (i < n) {
    chunks.push(arr.slice(i, i += len))
  }
  return chunks
}

export const confirmDialog = async(key = 'actions.deleteBulk') => {
  try {
    const data = await MessageBox.confirm(i18n.t(key).toString(), i18n.t('actions.warning').toString(), {
      confirmButtonText: i18n.t('actions.confirm').toString(),
      cancelButtonText: i18n.t('actions.cancel').toString(),
      customClass: 'bee-dialog',
      type: 'warning',
      center: true
    })
    return [data, null]
  } catch (error) {
    return [null, error]
  }
}

const toFixedFix = function(n: any, decs: any) {
  const k = Math.pow(10, decs)
  return Math.round(n * k) / k
}

export const numberFormat = (number: any, decimals = 0, decPoint = '.', thousandsSep = ',') => {
  const n = !isFinite(+number) ? 0 : +number
  const decs = !isFinite(+decimals) ? 0 : Math.abs(decimals)
  const s = (decs ? toFixedFix(n, decs) : Math.round(n)).toString().split('.')
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, thousandsSep)
  }
  if ((s[1] || '').length < decs) {
    s[1] = s[1] || ''
    s[1] += new Array(decs - s[1].length + 1).join('0')
  }
  return s.join(decPoint)
}

export const validateForm = async(form: Form) => {
  try {
    const data = await form.validate()
    return [data, null]
  } catch (err) {
    return [null, err]
  }
}

export const successMsg = (key: string) => {
  return Message.success({
    showClose: true,
    message: i18n.t(key).toString()
  })
}

export const errorMsg = (key: string, msgParams: any = {}) => {
  return Message.error({
    showClose: true,
    message: i18n.t(key, msgParams).toString()
  })
}

export function dateRange(startDate: any, endDate: any) {
  const start = startDate.split('-')
  const end = endDate.split('-')
  const startYear = parseInt(start[0])
  const endYear = parseInt(end[0])
  const dates = []

  for (let i = startYear; i <= endYear; i++) {
    const endMonth = i !== endYear ? 11 : parseInt(end[1]) - 1
    const startMon = i === startYear ? parseInt(start[1]) - 1 : 0
    for (let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
      const month = j + 1
      const displayMonth = month < 10 ? '0' + month : month
      dates.push([i, displayMonth, '01'].join('-'))
    }
  }
  return dates
}

export const getRandomColor = () => {
  const letters = '0123456789ABCDEF'
  let color = '#'
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

const S4 = () => {
  return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
}

export const guidGenerator = () => {
  return (S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4())
}

export const getSubText = (txt = '', limit = 200, suffix = '...') => {
  return (txt && txt.length > limit) ? txt.substring(0, limit) + suffix : txt
}

export const extractLatLongFromLocation = (location: any) => {
  if (!location || !location.coordinates || location.coordinates.length !== 2) {
    return null
  }

  if (isNaN(location.coordinates[0]) || isNaN(location.coordinates[1])) {
    return null
  }

  return {
    lat: location.coordinates[0],
    lng: location.coordinates[1]
  }
}

export const getTimeRangeFromString = (command: string) => {
  const range = new TimeRange()
  const curDate = new Date()
  switch (command) {
    case 'today': {
      const from = new Date()
      from.setUTCHours(0, 0, 0, 0)
      const to = new Date()
      to.setUTCHours(23, 59, 59, 999)
      range.from = from.getTime()
      range.to = to.getTime()
      break
    }
    case 'yesterday': {
      const from = new Date()
      from.setDate(from.getDate() - 1)
      from.setUTCHours(0, 0, 0, 0)
      const to = new Date()
      to.setDate(to.getDate() - 1)
      to.setUTCHours(23, 59, 59, 999)
      range.from = from.getTime()
      range.to = to.getTime()
      break
    }
    case 'last_seven_days': {
      const today = curDate.getTime()
      curDate.setDate(curDate.getDate() - 7)
      range.from = curDate.getTime()
      range.to = today
      break
    }
    case 'current_week': {
      const diff = curDate.getDate() - curDate.getDay() + (curDate.getDay() === 0 ? -6 : 1)
      range.from = new Date(curDate.setDate(diff)).getTime()
      range.to = curDate.getTime()
      break
    }
    case 'last_thirty_days': {
      const today = curDate.getTime()
      curDate.setDate(curDate.getDate() - 30)
      range.from = curDate.getTime()
      range.to = today
      break
    }
    case 'current_month': {
      range.from = new Date(curDate.getFullYear(), curDate.getMonth(), 1).getTime()
      range.to = curDate.getTime()
      break
    }
    case 'last_six_months': {
      const today = curDate.getTime()
      curDate.setMonth(curDate.getMonth() - 6)
      range.from = curDate.getTime()
      range.to = today
      break
    }
    case 'last_year': {
      const today = curDate.getTime()
      curDate.setFullYear(curDate.getFullYear() - 1)
      range.from = curDate.getTime()
      range.to = today
      break
    }
    case 'current_year': {
      range.from = new Date(curDate.getFullYear(), 0, 1).getTime()
      range.to = curDate.getTime()
      break
    }
  }
  return range
}

export const getFrontBaseUrl = () => {
  return UserModule.baseUrl
}

const checkIfTrue = (value: any) => {
  return value === 'true' || value === true
}

export const hasOfficeService = (service: string) => {
  const meta = UserModule.office.meta || {}
  if (hasRoles(['super_admin'], UserModule.roles)) {
    return true
  }
  if (!checkIfTrue(meta[service + '_enabled'])) {
    return false
  }
  if (service) {
    return !!meta[service + '_username'] && !!meta[service + '_password']
  }
  return false
}

export const showAttribute = (category: string, attribute: string) => {
  if (category === 'residence') {
    return residenceAttributes.includes(attribute)
  }
  if (category === 'business' || category === 'business_roof') {
    return businessAttributes.includes(attribute)
  }
  if (category === 'land') {
    return landAttributes.includes(attribute)
  }
  return false
}

export const strReplace = (search: any, replace: any, string: string) => {
  return string.replace(new RegExp('(' + (typeof (search) === 'string' ? search.replace(/[.?*+^$[\]\\(){}|-]/g, '\\') : search.map(function(i: any) { return i.replace(/[.?*+^$[\]\\(){}|-]/g, '\\') }).join('|')) + ')', 'g'), typeof (replace) === 'string' ? replace : typeof (search) === 'string' ? replace[0] : function(i: any) { return replace[search.indexOf(i)] })
}

export const greeklish = (string = '') => {
  const greek = ['α', 'ά', 'Ά', 'Α', 'β', 'Β', 'γ', 'Γ', 'δ', 'Δ', 'ε', 'έ', 'Ε', 'Έ', 'ζ', 'Ζ', 'η', 'ή', 'Η', 'θ', 'Θ', 'ι', 'ί', 'ϊ', 'ΐ', 'Ι', 'Ί', 'κ', 'Κ', 'λ', 'Λ', 'μ', 'Μ', 'ν', 'Ν', 'ξ', 'Ξ', 'ο', 'ό', 'Ο', 'Ό', 'π', 'Π', 'ρ', 'Ρ', 'σ', 'ς', 'Σ', 'τ', 'Τ', 'υ', 'ύ', 'Υ', 'Ύ', 'φ', 'Φ', 'χ', 'Χ', 'ψ', 'Ψ', 'ω', 'ώ', 'Ω', 'Ώ', ' ', "'", "'"]
  const english = ['a', 'a', 'A', 'A', 'b', 'B', 'g', 'G', 'd', 'D', 'e', 'e', 'E', 'E', 'z', 'Z', 'i', 'i', 'I', 'th', 'Th', 'i', 'i', 'i', 'i', 'I', 'I', 'k', 'K', 'l', 'L', 'm', 'M', 'n', 'N', 'x', 'X', 'o', 'o', 'O', 'O', 'p', 'P', 'r', 'R', 's', 's', 'S', 't', 'T', 'u', 'u', 'Y', 'Y', 'f', 'F', 'ch', 'Ch', 'ps', 'Ps', 'o', 'o', 'O', 'O', ' ', '_', '_']
  return strReplace(greek, english, string)
}

const createScriptElem = (src:string) => {
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.async = true
  script.src = src
  return script
}

const unloadScript = (src:string) => {
  return new Promise<void>(function(resolve, reject) {
    const el = document.querySelector('script[src="' + src + '"]')
    if (!el) {
      // eslint-disable-next-line prefer-promise-reject-errors
      reject()
      return
    }
    document.head.removeChild(el)
    resolve()
  })
}

const loadScript = (src:string) => {
  return new Promise((resolve, reject) => {
    let shouldAppend = false
    let el = document.querySelector('script[src="' + src + '"]') as any
    if (!el) {
      el = createScriptElem(src)
      shouldAppend = true
    } else if (el.hasAttribute('data-loaded')) {
      resolve(el)
      return
    }

    el.addEventListener('error', reject)
    el.addEventListener('abort', reject)
    el.addEventListener('load', function loadScriptHandler() {
      el?.setAttribute('data-loaded', 'true')
      resolve(el)
    })

    if (shouldAppend) {
      document.head.appendChild(el)
    }
  })
}

const connect = (callback: any) => {
  // eslint-disable-next-line no-undef
  const socket = new (window as any).SockJS('/messages')
  // eslint-disable-next-line no-undef
  const stompClient = (window as any).Stomp.over(socket)
  stompClient.debug = null
  stompClient.connect(
    {},
    () => {
      stompClient.subscribe('/topic/user-' + UserModule.id, (tick: any) => {
        const messages = JSON.parse(tick.body) || []
        messages.forEach(callback)
      })
    },
    (error: any) => {
      console.log(error)
    }
  )
}

export const initSocket = async(callback :any) => {
  await loadScript('https://cdn.jsdelivr.net/sockjs/1/sockjs.min.js')
  await loadScript('https://cdnjs.cloudflare.com/ajax/libs/stomp.js/2.3.3/stomp.min.js')
  connect(callback)
}

export const getOfficeLogoUrl = (office: any) => {
  return '/static/offices/' + office.id + '/images/' + office.logo
}

import { RouteConfig } from 'vue-router'
import Layout from '@/layout/index.vue'

const chartsRouter: RouteConfig = {
  path: '/recommendation',
  component: Layout,
  redirect: '/recommendation/list',
  meta: {
    title: 'recommendation',
    icon: 'el-icon-recommendation',
    privileges: ['VIEW_RECOMMENDATIONS']
  },
  children: [
    {
      path: 'edit',
      name: 'Menu1',
      component: () => import(/* webpackChunkName: "recommendation-create-client" */ '@/views/recommendation/edit/index.vue'),
      meta: {
        hidden: true,
        title: 'edit'
      },
      children: [
        {
          path: 'client/:id',
          component: () => import(/* webpackChunkName: "recommendation-edit-client" */ '@/views/recommendation/edit/client/index.vue'),
          name: 'EditClientRecommendation',
          meta: {
            title: 'editClientRecommendation',
            icon: 'el-icon',
            noCache: true,
            activeMenu: '/outgoing-recommendation/list',
            hidden: true,
            privileges: ['EDIT_RECOMMENDATIONS', 'READ_RECOMMENDATIONS']
          }
        },
        {
          path: 'property/:id',
          component: () => import(/* webpackChunkName: "recommendation-edit-property" */ '@/views/recommendation/edit/property/index.vue'),
          name: 'EditPropertyRecommendation',
          meta: {
            title: 'editPropertyRecommendation',
            icon: 'el-icon',
            noCache: true,
            activeMenu: '/outgoing-recommendation/list',
            hidden: true,
            privileges: ['EDIT_RECOMMENDATIONS', 'READ_RECOMMENDATIONS']
          }
        }
      ]
    },
    {
      path: 'create',
      name: 'Menu1',
      component: () => import(/* webpackChunkName: "recommendation-create-client" */ '@/views/recommendation/create/index.vue'),
      meta: {
        title: 'createRecommendation',
        icon: 'el-icon',
      },
      children: [
        {
          path: 'property',
          component: () => import(/* webpackChunkName: "recommendation-create-property" */ '@/views/recommendation/create/property/index.vue'),
          meta: {
            title: 'createPropertyRecommendation',
            icon: 'el-icon',
            privileges: ['CREATE_RECOMMENDATIONS']
          }
        },
        {
          path: 'client',
          component: () => import(/* webpackChunkName: "recommendation-create-client" */ '@/views/recommendation/create/client/index.vue'),
          meta: {
            title: 'createClientRecommendation',
            icon: 'el-icon',
            privileges: ['CREATE_RECOMMENDATIONS']
          }
        }
      ]
    },
    {
      path: 'incoming',
      name: 'Menu1',
      meta: {
        title: 'incoming',
        icon: 'el-icon',
      },
      component: () => import(/* webpackChunkName: "recommendation-incoming-property-list" */ '@/views/recommendation/incoming/index.vue'),
      children: [
        {
          path: 'property-list',
          component: () => import(/* webpackChunkName: "recommendation-incoming-property-list" */ '@/views/recommendation/incoming/property/index.vue'),
          meta: {
            title: 'incomingPropertyRecommendationList',
            icon: 'el-icon',
            privileges: ['LIST_RECOMMENDATIONS']
          }
        },
        {
          path: 'client-list',
          component: () => import(/* webpackChunkName: "recommendation-incoming-client-list" */ '@/views/recommendation/incoming/client/index.vue'),
          meta: {
            title: 'incomingClientRecommendationList',
            icon: 'el-icon',
            privileges: ['LIST_RECOMMENDATIONS']
          }
        }
      ]
    },
    {
      path: 'outgoing',
      redirect: '/recommendation/list',
      name: 'Menu2',
      meta: {
        title: 'outgoing',
        icon: 'el-icon',
      },
      component: () => import(/* webpackChunkName: "recommendation-outgoing-property-list" */ '@/views/recommendation/outgoing/index.vue'),
      children: [
        {
          path: 'property-list',
          component: () => import(/* webpackChunkName: "recommendation-outgoing-property-list" */ '@/views/recommendation/outgoing/property/index.vue'),
          meta: {
            title: 'outgoingPropertyRecommendationList',
            icon: 'el-icon',
            privileges: ['LIST_RECOMMENDATIONS']
          }
        },
        {
          path: 'client-list',
          component: () => import(/* webpackChunkName: "recommendation-outgoing-client-list" */ '@/views/recommendation/outgoing/client/index.vue'),
          meta: {
            title: 'outgoingClientRecommendationList',
            icon: 'el-icon',
            privileges: ['LIST_RECOMMENDATIONS']
          }
        }
      ]
    }
  ]
}

export default chartsRouter

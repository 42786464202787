import request from '@/utils/request'
import { createFilter, getSearchFilter } from '@/utils/filter'

export const getClientsLight = (data: any) =>
  request({
    method: 'post',
    url: '/api/helpers/getClientsLight',
    data
  })

export const getPropertiesLight = (data: any) =>
  request({
    method: 'post',
    url: '/api/helpers/getPropertiesLight',
    data
  })

export const getUsersLight = (data: any) =>
  request({
    method: 'post',
    url: '/api/helpers/getUsersLight',
    data
  })

export const getOfficesLight = (data: any) =>
  request({
    method: 'post',
    url: '/api/helpers/getOfficesLight',
    data
  })

export const getRemoteProperties = async(query: string, items: any = []) => {
  try {
    if (!query) return []
    const { data } = await getPropertiesLight(createFilter([getSearchFilter(
      query, [{
        key: 'full_code'
      }]
    ), ...items]))
    return data.collection
  } catch (err) {
    return []
  }
}

export const getRemoteUsers = async(query: string, items: any = [], options: any = {}) => {
  try {
    if (!query) return []
    const { data } = await getUsersLight(createFilter([getSearchFilter(
      query, [{
        type: 'function',
        key: 'translations.first_name,translations.last_name',
        function_name: 'concat',
        options: {
          ignore_case: true,
          unaccent: true
        }
      }, {
        type: 'function',
        key: 'translations.last_name,translations.first_name',
        function_name: 'concat',
        options: {
          ignore_case: true,
          unaccent: true
        }
      }]
    ), ...items], options))
    return data.collection
  } catch (err) {
    return []
  }
}

export const getRemoteClients = async(query: string, items: any = []) => {
  try {
    if (!query) return []
    const { data } = await getClientsLight(createFilter([getSearchFilter(
      query, [{
        type: 'function',
        key: 'translations.first_name,translations.last_name',
        function_name: 'concat',
        options: {
          ignore_case: true,
          unaccent: true
        }
      }, {
        type: 'function',
        key: 'translations.last_name,translations.first_name',
        function_name: 'concat',
        options: {
          ignore_case: true,
          unaccent: true
        }
      }]
    ), ...items]))
    return data.collection || []
  } catch (err) {
    return []
  }
}

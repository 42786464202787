import { RouteConfig } from 'vue-router'
import Layout from '@/layout/index.vue'

const newsRouter: RouteConfig = {
  path: '/news',
  component: Layout,
  redirect: '/news/list',
  meta: {
    title: 'news',
    icon: 'el-icon-news',
    privileges: ['VIEW_NEWS']
  },
  children: [
    {
      path: 'create',
      component: () => import(/* webpackChunkName: "news-create" */ '@/views/news/create.vue'),
      meta: {
        title: 'createNew',
        icon: 'el-icon',
        privileges: ['CREATE_NEWS']
      }
    },
    {
      path: 'edit/:id',
      component: () => import(/* webpackChunkName: "news-edit" */ '@/views/news/edit.vue'),
      name: 'EditNew',
      meta: {
        title: 'editNew',
        icon: 'el-icon',
        noCache: true,
        activeMenu: '/news/list',
        hidden: true,
        privileges: ['EDIT_NEWS', 'READ_NEWS']
      }
    },
    {
      path: 'list',
      component: () => import(/* webpackChunkName: "news-list" */ '@/views/news/list.vue'),
      meta: {
        title: 'newsList',
        icon: 'el-icon',
        privileges: ['LIST_NEWS']
      }
    }
  ]
}

export default newsRouter

import { RouteConfig } from 'vue-router'
import Layout from '@/layout/index.vue'

const chartsRouter: RouteConfig = {
  path: '/office',
  component: Layout,
  redirect: '/office/list',
  meta: {
    title: 'office',
    icon: 'el-icon-offices',
    privileges: ['VIEW_OFFICES', 'VIEW_COLLABORATORSTATISTICS']
  },
  children: [
    {
      path: 'create',
      component: () => import(/* webpackChunkName: "office-create" */ '@/views/office/create.vue'),
      meta: {
        title: 'createOffice',
        icon: 'el-icon',
        privileges: ['CREATE_OFFICES']
      }
    },
    {
      path: 'editOfficePage/:id',
      component: () => import(/* webpackChunkName: "office-edit-page" */ '@/views/office/office-page.vue'),
      name: 'EditOfficePage',
      meta: {
        title: 'editOfficePage',
        icon: 'el-icon',
        noCache: true,
        activeMenu: '/office/list',
        hidden: true,
        privileges: ['CREATE_THEME_TEMPLATES', 'EDIT_THEME_TEMPLATES']
      }
    },
    {
      path: 'edit/:id',
      component: () => import(/* webpackChunkName: "office-edit" */ '@/views/office/edit.vue'),
      name: 'EditOfficePage',
      meta: {
        title: 'editOffice',
        icon: 'el-icon',
        noCache: true,
        activeMenu: '/office/list',
        hidden: true,
        privileges: ['EDIT_OFFICES']
      }
    },
    {
      path: 'collaborators-statistics',
      component: () => import(/* webpackChunkName: "collaborators-statistics" */ '@/views/office/statistics.vue'),
      meta: {
        title: 'collaboratorsStatistics',
        icon: 'el-icon',
        privileges: ['VIEW_COLLABORATORSTATISTICS']
      }
    },
    {
      path: 'list',
      component: () => import(/* webpackChunkName: "office-list" */ '@/views/office/list.vue'),
      meta: {
        title: 'officeList',
        icon: 'el-icon',
        privileges: ['LIST_OFFICES']
      }
    }
  ]
}

export default chartsRouter

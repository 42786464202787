import { DirectiveOptions } from 'vue'
import { DirectiveFunction } from 'vue/types/options'
export const fix: DirectiveOptions = {
  inserted(el, binding) {
    const input:any = el.querySelector('.el-input__inner');
    input.removeAttribute('readonly');
  },
  componentUpdated(el, binding) {
    const input:any = el.querySelector('.el-input__inner');
    input.removeAttribute('readonly');
  }
}

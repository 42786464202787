import { RouteConfig } from 'vue-router'
import Layout from '@/layout/index.vue'

const chartsRouter: RouteConfig = {
  path: '/role',
  component: Layout,
  redirect: '/role/list',
  meta: {
    title: 'role',
    icon: 'el-icon-s-operation',
    privileges: ['VIEW_ROLES']
  },
  children: [
    {
      path: 'list',
      component: () => import(/* webpackChunkName: "role-list" */ '@/views/role/list.vue'),
      meta: {
        title: 'roleList',
        icon: 'el-icon',
        privileges: ['LIST_ROLES']
      }
    },
    {
      path: 'privilege/list',
      component: () => import(/* webpackChunkName: "privilege-list" */ '@/views/privilege/list.vue'),
      meta: {
        title: 'privilegeList',
        icon: 'el-icon',
        privileges: ['LIST_PRIVILEGES']
      }
    }
  ]
}

export default chartsRouter
